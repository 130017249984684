/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Event from "../lib/Event";
//import FirebaseUtils from "lib/FirebaseUtils";
import PubSub from "../lib/PubSub";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "underscore";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonIcon,
} from "@ionic/react";

import chicken from "../assets/img/icons/protein_chicken_white.svg";
import beef from "../assets/img/icons/protein_beef_white.svg";
import pork from "../assets/img/icons/protein_pork_white.svg";
import turkey from "../assets/img/icons/protein_turkey_white.svg";
import plant from "../assets/img/icons/protein_plant_white.svg";
import egg from "../assets/img/icons/protein_egg_white.svg";
import other from "../assets/img/icons/protein_other_white.svg";
import fish from "../assets/img/icons/protein_fish_white.svg";
import glutenFree from "../assets/img/icons/gluten_free.svg";
import { add, remove } from "ionicons/icons";
import StringUtils from "../lib/StringUtils";
import SegmentUtils from "../lib/SegmentUtils";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Capacitor } from "@capacitor/core";

class MealOptionCard extends React.Component<{
  mobile?: boolean;
  meal?: any;
  selected?: any;
  disableAdd?: boolean;
  incrementMeal?: any;
  compact?: boolean;
  checkoutMode?: boolean;
  modalID?: string;
  position?: number;
  favorite?: boolean;
  allowFavorite?: boolean;
  onFavoriteChange?: any;
}> {
  state: any = {};

  openMealInfo(meal: any) {
    if (this.props.checkoutMode) {
      return;
    }

    // Haptic feedback
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }

    let props: any = {
      brand: "Project LeanNation",
      category: meal?.planType?.name + " Meals",
      image_url: meal?.imageURL,
      name: meal?.name,
      product_id: meal?._id,
      url: meal?.deeplinkURL,
    };

    if (this.props.position) {
      props.position = this.props.position;
    }

    SegmentUtils.track("Product Clicked", props);

    PubSub.publish(Event.MEAL.OPEN_INFO, { meal, id: this.props.modalID });
  }

  renderTopCategory(meal: any) {
    let top: any = "";
    let priority = 0;

    if (meal?.name?.includes("Lemon Cajun")) {
      console.log(meal?.categories);
    }

    if (!meal?.categories?.length) {
      return <></>;
    }

    for (let i = 0; i < meal.categories.length; i++) {
      let cat = meal.categories[i];

      if (cat.name == "Best Sellers" && priority <= 110) {
        top = (
          <div
            className={`inline-block px-sm-2 text-primary font-weight-700 text-uppercase ${
              !this.props.mobile && "px-1 py-[0.1rem]"
            }`}
            style={{
              fontSize: this.props.mobile ? 10 : "12px",
              borderRadius: "3px",
              background: "var(--primary-ultralight)",
              border: "1px solid var(--primary)",
              paddingLeft: this.props.mobile ? "0.2rem" : "",
              paddingRight: this.props.mobile ? "0.2rem" : "",
              paddingTop: this.props.mobile ? "0.15rem" : "",
              paddingBottom: this.props.mobile ? "0.15rem" : "",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 110;
      }

      if (cat.name == "New" && priority <= 105) {
        top = (
          <div
            className="inline-block px-sm-2 px-1 py-[0.1rem] text-primary text-uppercase border border-primary font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              background: "var(--primary-ultralight)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 110;
      }

      if (cat.name == "Lean Cheats" && priority <= 100) {
        top = (
          <div
            color="danger"
            className="inline-block px-sm-2 px-1 py-[0.1rem] bg-white text-dark text-uppercase font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 100;
      }

      if (cat.name == "Plant Based" && priority <= 90) {
        top = (
          <div
            color="success"
            className="inline-block px-sm-2 px-1 py-[0.1rem] bg-white text-dark text-uppercase font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 90;
      }

      if (cat.name == "Grain Free" && priority <= 80) {
        top = (
          <div
            color="warning"
            className="inline-block px-sm-2 px-1 py-[0.1rem] bg-white text-uppercase text-dark font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 80;
      }

      if (cat.name == "Dairy Free" && priority <= 70) {
        top = (
          <div
            color="info"
            className="inline-block px-sm-2 px-1 py-[0.1rem] bg-white text-uppercase text-dark font-weight-bold"
            style={{
              fontSize: this.props.mobile ? 11 : "12px",
              borderRadius: "3px",
              border: "1px solid var(--light)",
            }}
          >
            {cat.name}
          </div>
        );
        priority = 70;
      }
    }

    if (!top) {
      top = (
        <div
          color="info"
          className="inline-block px-sm-2 px-1 py-[0.1rem] bg-white text-uppercase text-dark font-weight-bold"
          style={{
            fontSize: this.props.mobile ? 11 : "12px",
            borderRadius: "3px",
            border: "1px solid var(--light)",
          }}
        >
          Balanced
        </div>
      );
    }

    return top;
  }

  getSegmentProps() {
    let props: any = {
      brand: "Project LeanNation",
      category: this.props.meal?.planType?.name + " Meals",
      category_id: this.props.meal?.planType?._id,
      image_url: this.props.meal?.imageURL,
      name: this.props.meal?.name,
      product_id: this.props.meal?._id,
      url: this.props.meal?.deeplinkURL,
      quantity: 1,
    };

    if (this.props.position) {
      props.position = this.props.position;
    }

    return props;
  }

  isInSelected(meal: any) {
    let i = _.findWhere(this.state.selectedMeals, { _id: meal._id });

    if (i) {
      return true;
    }

    return false;
  }

  getProteinIcon(meal: any) {
    if (!meal?.primaryProteinCategory) {
      return "";
    }

    const cat = meal?.primaryProteinCategory?.toLowerCase();

    if (cat == "chicken") {
      return chicken;
    }

    if (cat == "beef") {
      return beef;
    }

    if (cat == "pork") {
      return pork;
    }

    if (cat == "turkey") {
      return turkey;
    }

    if (cat == "fish") {
      return fish;
    }

    if (cat == "plant based") {
      return plant;
    }

    if (cat == "lean cheat") {
      return other;
    }

    if (cat == "egg") {
      return egg;
    }
  }

  stripHTML(html: any) {
    return html.replace(/(<([^>]+)>)/gi, "");
  }

  convertToPlain(html: any) {
    return StringUtils.htmlToText(html);
  }

  render() {
    return (
      <>
        {this.props.mobile ? (
          <>
            <IonCard
              mode="ios"
              className="m-0 meal-info-card "
              style={{
                borderRadius: 0,
                boxShadow: "none",
              }}
            >
              <IonCardContent
                className="p-0 bg-white rounded-md border-[1px] md:border-[0px] border-gray-100"
                style={{}}
              >
                <div className="d-flex">
                  <div
                    onClick={() => this.openMealInfo(this.props.meal)}
                    className={`my-2 ml-2 mr-1 rounded border-[1px] border-orange-300 ${
                      !this.props.checkoutMode && "cursor-pointer"
                    }`}
                    style={{
                      height: "auto",
                      width: this.props.checkoutMode ? "80px" : "95px",
                      background: "var(--primary-lighter)",
                      position: "relative",
                      minHeight: this.props.compact ? "80px" : "110px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${
                          this.props.meal.platedImageURL
                            ? this.props.meal.platedImageURL
                            : this.props.meal.imageURL
                        })`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: this.props.meal?.platedImageURL
                          ? "160%"
                          : "240%",
                        position: "absolute",
                        left: 0,
                        top: 0,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: "0.2rem",
                        bottom: "0.25rem",
                        background: "var(--primary-darker)",
                        borderRadius: "100%",
                        width: 25,
                        height: 25,
                      }}
                      className="text-right "
                    >
                      <img
                        src={this.getProteinIcon(this.props.meal)}
                        style={{ width: "25px" }}
                      ></img>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: "0.2rem",
                        bottom: "0.25rem",
                        background: "var(--primary-darker)",
                        borderRadius: "100%",
                        width: 20,
                        height: 20,
                      }}
                      className="text-right "
                    >
                      <img
                        src={glutenFree}
                        style={{ width: "20px", position: "relative", top: -2 }}
                      ></img>
                    </div>
                  </div>
                  <div
                    onClick={() => this.openMealInfo(this.props.meal)}
                    className={`p-2 ${
                      !this.props.checkoutMode && "cursor-pointer"
                    }`}
                    style={{
                      width: `calc(100% - ${
                        this.props.checkoutMode ? "80px" : "95px"
                      })`,
                    }}
                  >
                    <div className={`mb-1 ${this.props.compact && "d-none"}`}>
                      <IonGrid className="p-0">
                        <IonRow className="ion-ion-align-items-center">
                          <IonCol className="pl-0 py-0" size-xs="6">
                            <div
                              className={`${
                                this.props.meal?.retired
                                  ? "sm:flex gap-x-2 lg:block gap-y-1"
                                  : "inline-block"
                              }`}
                            >
                              {this.props.meal?.retired ? (
                                <>
                                  <div
                                    className={`inline-block mr-[0.45rem] sm:mr-0 px-sm-2 bg-yellow-200 border-[1px] border-yellow-900 text-gray-900 font-weight-700 text-uppercase ${
                                      !this.props.mobile && "px-1 py-[0.1rem]"
                                    }`}
                                    style={{
                                      fontSize: this.props.mobile ? 11 : "12px",
                                      borderRadius: "3px",

                                      paddingLeft: this.props.mobile
                                        ? "0.2rem"
                                        : "",
                                      paddingRight: this.props.mobile
                                        ? "0.2rem"
                                        : "",
                                      paddingTop: this.props.mobile
                                        ? "0.15rem"
                                        : "",
                                      paddingBottom: this.props.mobile
                                        ? "0.15rem"
                                        : "",
                                    }}
                                  >
                                    Limited Time Only
                                  </div>
                                </>
                              ) : null}
                              <div className="flex gap-x-2">
                                {this.renderTopCategory(this.props.meal)}
                                {this.props.meal?.spicy ? (
                                  <div
                                    style={{ lineHeight: 1 }}
                                    className="inline-block h-[20.84px] sm:ml-0 px-1 border-[1px] border-red-200 bg-red-50 rounded text-gray-900"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="140px"
                                      height="185px"
                                      viewBox="0 0 140 185"
                                      version="1.1"
                                      style={{
                                        height: 13,
                                        width: "auto",
                                        position: "relative",
                                        top: 3,
                                      }}
                                    >
                                      <title>Meal Heat Flame</title>
                                      <g
                                        id="Meal-Heat-Flame"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <path
                                          d="M107.681558,0.0427138334 C107.659186,0.0643153614 107.637728,0.0869484654 107.617246,0.110547115 C90.7193105,5.68520951 77.1764537,22.2532462 69.8716471,40.7059469 C62.8424875,58.4622994 61.7742218,77.9317946 69.2919377,91.4332384 C58.843956,92.1203807 50.0433588,89.4909601 45.1373211,81.8453565 C39.9927803,73.8280793 38.8693021,60.0655214 45.0084773,38.4619868 C45.11997,38.0538801 44.9963276,37.6145904 44.6918305,37.3369706 C44.3873335,37.0593508 43.9576496,36.994155 43.5914148,37.170005 C9.18969463,55.8789305 -4.47664656,92.8150112 1.27256306,125.092861 C7.02172892,157.370712 32.2868081,185 74.9602098,185 C94.77662,185 111.592939,177.831255 123.140599,164.532188 C134.688259,151.233341 140.928016,131.844035 139.887794,107.685035 C138.843459,80.1224131 125.131181,67.2739748 114.895824,54.5777429 C109.778168,48.2296489 105.567777,41.903777 103.945745,33.770038 C102.323714,25.636321 103.289933,15.5507234 108.905486,1.53852578 C109.072021,1.15188858 109.010228,0.698295065 108.747234,0.376870473 C108.48424,0.0554458807 108.068124,-0.0750500267 107.681558,0.0426697859 L107.681558,0.0427138334 Z"
                                          id="Path"
                                          fill="#F5365C"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </IonCol>
                          <IonCol
                            className="pr-0 py-0 text-right flex items-center justify-end"
                            size-xs="6"
                          >
                            {this.props.allowFavorite && this.props.favorite ? (
                              <>
                                <div className="rounded-md text-red-500 bg-gray-25 border-[1px] border-gray-100 mr-2 p-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="h-3 w-3"
                                  >
                                    <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
                                  </svg>
                                </div>
                              </>
                            ) : null}
                            <div
                              className="px-1 py-1 bg-gray-25 d-inline-block items-center text-gray-900 font-weight-bold border-[1px] d-inline-block border-gray-100 text-uppercase"
                              style={{
                                fontSize: this.props.checkoutMode ? 10 : 11,
                                lineHeight: 1,
                                borderRadius: 3,
                              }}
                            >
                              <div className="flex gap-x-1 items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="h-3 w-3"
                                >
                                  <circle cx="12" cy="12" r="10" />
                                  <path d="M12 16v-4" />
                                  <path d="M12 8h.01" />
                                </svg>
                                <div>Info</div>
                              </div>
                            </div>
                            {this.props.selected > 0 ? (
                              <div
                                className="px-1 ml-2 py-1 bg-dark text-white font-weight-bold border d-inline-block border-dark text-uppercase"
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: this.props.checkoutMode ? 10 : 11,
                                  lineHeight: 1,
                                  borderRadius: 3,
                                }}
                              >
                                {this.props.selected} In Box
                              </div>
                            ) : null}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>

                    <div>
                      <h3
                        className={`${
                          !this.props.compact && "mt-2"
                        } mb-0 text-truncate text-dark font-weight-bold ${
                          this.props.checkoutMode &&
                          "d-flex ion-align-items-center"
                        }`}
                        style={{ fontSize: 16 }}
                      >
                        <div
                          style={{
                            flexGrow: this.props.checkoutMode ? "1" : "",
                          }}
                          className={
                            this.props.checkoutMode ? "text-truncate" : ""
                          }
                        >
                          {this.props.meal.name}
                        </div>
                        {this.props.checkoutMode && (
                          <>
                            <div className="ml-1 flex items-center">
                              {this.props.meal?.retired ? (
                                <>
                                  <div
                                    className={`inline-block mr-[0.45rem] sm:mr-0 px-sm-2 bg-yellow-200 border-[1px] border-yellow-900 text-gray-900 font-weight-700 text-uppercase ${
                                      !this.props.mobile && "px-1 py-[0.1rem]"
                                    }`}
                                    style={{
                                      fontSize: this.props.mobile ? 11 : "12px",
                                      borderRadius: "3px",

                                      paddingLeft: this.props.mobile
                                        ? "0.2rem"
                                        : "",
                                      paddingRight: this.props.mobile
                                        ? "0.2rem"
                                        : "",
                                      paddingTop: this.props.mobile
                                        ? "0.15rem"
                                        : "",
                                      paddingBottom: this.props.mobile
                                        ? "0.15rem"
                                        : "",
                                    }}
                                  >
                                    Limited Time Only
                                  </div>
                                </>
                              ) : null}
                              <div
                                className="px-1 py-1 bg-gray-50 d-inline-block items-center text-gray-900 font-weight-bold border-[1px] d-inline-block border-gray-100 text-uppercase"
                                style={{
                                  fontSize: this.props.checkoutMode ? 10 : 11,
                                  lineHeight: 1,
                                  borderRadius: 3,
                                }}
                              >
                                <div className="flex gap-x-1 items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="h-3 w-3"
                                  >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M12 16v-4" />
                                    <path d="M12 8h.01" />
                                  </svg>
                                  <div>Info</div>
                                </div>
                              </div>
                              {this.props.selected > 0 ? (
                                <div
                                  className="px-1 ml-2 py-1 bg-dark text-white font-weight-bold border d-inline-block border-dark text-uppercase"
                                  style={{
                                    fontSize: this.props.checkoutMode ? 10 : 11,
                                    lineHeight: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  {this.props.selected} In Box
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                      </h3>
                      <div
                        className={`meal-info-description mt-1 ${
                          this.props.checkoutMode && "compact"
                        }`}
                      >
                        {this.convertToPlain(this.props.meal.description)}
                      </div>

                      <div
                        className={`${
                          this.props.compact && !this.props.checkoutMode
                            ? "d-block mt-1"
                            : "d-none"
                        }`}
                      >
                        <IonGrid className="p-0">
                          <IonRow className="ion-ion-align-items-center">
                            <IonCol
                              className="pr-0 py-0 text-right flex items-center justify-end"
                              size-xs="12"
                            >
                              <div
                                className="px-1 py-1 bg-gray-50 d-inline-block items-center text-gray-900 font-weight-bold border-[1px] d-inline-block border-gray-100 text-uppercase"
                                style={{
                                  fontSize: this.props.checkoutMode ? 10 : 11,
                                  lineHeight: 1,
                                  borderRadius: 3,
                                }}
                              >
                                <div className="flex gap-x-1 items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="h-3 w-3"
                                  >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M12 16v-4" />
                                    <path d="M12 8h.01" />
                                  </svg>
                                  <div>Info</div>
                                </div>
                              </div>
                              {this.props.selected > 0 ? (
                                <div
                                  className="px-1 ml-2 py-1 bg-dark text-white font-weight-bold border d-inline-block border-dark text-uppercase"
                                  style={{
                                    fontSize: this.props.checkoutMode ? 10 : 11,
                                    lineHeight: 1,
                                    borderRadius: 3,
                                  }}
                                >
                                  {this.props.selected} In Box
                                </div>
                              ) : null}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    this.props.compact && "d-none"
                  } px-2 flex items-center pb-2`}
                >
                  <div className={`grow`}>
                    <IonRow className="">
                      <IonCol size-xs="auto" className="pr-2 pl-0">
                        <div className="rounded-md bg-gray-25 border-[1px] border-gray-100 px-2 py-[0.15rem] text-center">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.calories}
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "10px", lineHeight: 1 }}
                          >
                            Cal
                          </p>
                        </div>
                      </IonCol>
                      <IonCol size-xs="auto" className="pr-2 pl-0">
                        <div className="rounded-md bg-gray-25 border-[1px] border-gray-100 px-2 py-[0.15rem] text-center">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.protein}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "10px", lineHeight: 1 }}
                          >
                            Protein
                          </p>
                        </div>
                      </IonCol>
                      <IonCol size-xs="auto" className="pr-2 pl-0">
                        <div className="rounded-md bg-gray-25 border-[1px] border-gray-100 px-2 py-[0.15rem] text-center">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.carbs}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "10px", lineHeight: 1 }}
                          >
                            Carbs
                          </p>
                        </div>
                      </IonCol>
                      <IonCol size-xs="auto" className="pr-2 pl-0">
                        <div className="rounded-md bg-gray-25 border-[1px] border-gray-100 px-2 py-[0.15rem] text-center">
                          <p
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                            style={{ fontSize: 13 }}
                          >
                            {this.props.meal?.nutrition?.fat}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "10px", lineHeight: 1 }}
                          >
                            Fats
                          </p>
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                  <div
                    className={`${
                      this.props.checkoutMode ? "d-none" : "d-flex"
                    } rounded-lg grid-cols-2 gap-x-4 px-2 bg-orange-500 border-[1px] border-orange-700 h-[34px] items-center`}
                  >
                    {this.props.selected > 0 ? (
                      <>
                        <div
                          className="p-[0.2rem]"
                          onClick={() => {
                            this.props.incrementMeal(this.props.meal, -1);

                            if (Capacitor.isNativePlatform()) {
                              Haptics.impact({ style: ImpactStyle.Light });
                            }

                            SegmentUtils.track(
                              "Product Removed",
                              this.getSegmentProps()
                            );
                          }}
                        >
                          <div className="d-flex text-center ion-align-items-center cursor-pointer">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="text-white"
                              >
                                <path d="M5 12h14" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div
                          className="p-[0.2rem]"
                          onClick={() => {
                            if (this.props.disableAdd) {
                              return;
                            }

                            if (Capacitor.isNativePlatform()) {
                              Haptics.impact({ style: ImpactStyle.Light });
                            }

                            SegmentUtils.track(
                              "Product Added",
                              this.getSegmentProps()
                            );

                            this.props.incrementMeal(this.props.meal, 1);
                          }}
                          style={{
                            cursor: this.props.disableAdd
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          <div className="d-flex text-center items-center">
                            <div
                              style={{
                                lineHeight: 1,
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className={`${
                                  this.props.disableAdd
                                    ? "text-orange-400"
                                    : "text-white"
                                }`}
                              >
                                <path d="M5 12h14" />
                                <path d="M12 5v14" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            if (this.props.disableAdd) {
                              return;
                            }

                            this.props.incrementMeal(this.props.meal, 1);

                            if (Capacitor.isNativePlatform()) {
                              Haptics.impact({ style: ImpactStyle.Light });
                            }

                            SegmentUtils.track(
                              "Product Added",
                              this.getSegmentProps()
                            );
                          }}
                          className={``}
                        >
                          <div
                            className="d-flex grid-cols-2 gap-x-2 text-center ion-align-items-center"
                            style={{
                              cursor: this.props.disableAdd
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className={`${
                                this.props.disableAdd
                                  ? "text-orange-400"
                                  : "text-white"
                              }`}
                            >
                              <path d="M5 12h14" />
                              <path d="M12 5v14" />
                            </svg>
                            <div
                              className={`${
                                this.props.disableAdd
                                  ? "text-orange-400"
                                  : "text-white"
                              } font-bold text-sm`}
                            >
                              Add
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <>
            <IonCard mode="ios" className="shadow m-0 border">
              <IonCardContent
                className={`text-center p-0 ${
                  !this.props.checkoutMode && "cursor-pointer"
                }`}
              >
                <div
                  style={{
                    flexGrow: 1,
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    position: "relative",
                    background: "var(--primary-superlight)",
                  }}
                  className="p-0 border-bottom"
                  onClick={() => this.openMealInfo(this.props.meal)}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0.5rem",
                      left: "0.5rem",
                      lineHeight: 1,

                      fontSize: "14px",
                    }}
                    className="flex items-center"
                  >
                    {this.props.allowFavorite && this.props.favorite ? (
                      <>
                        <div className="d-inline-block rounded-md text-red-500 bg-gray-25 border-[1px] border-gray-100 mr-2 p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="h-[13px] w-[13px]"
                          >
                            <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
                          </svg>
                        </div>
                      </>
                    ) : null}

                    <div className="px-1 rounded-md py-1 bg-gray-25 d-inline-block items-center text-gray-900 font-weight-bold border-[1px] d-inline-block border-gray-100 text-uppercase">
                      <div className="flex gap-x-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="h-[13px] w-[13px]"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <path d="M12 16v-4" />
                          <path d="M12 8h.01" />
                        </svg>
                        <div>Info</div>
                      </div>
                    </div>
                  </div>
                  {this.props.selected > 0 ? (
                    <div
                      className="ml-2 cursor-pointer bg-primary text-white px-2 py-1 text-uppercase font-weight-bold"
                      style={{
                        position: "absolute",
                        top: "0.5rem",
                        right: "0.5rem",
                        lineHeight: 1,
                        borderRadius: "3px",
                        fontSize: "14px",
                      }}
                    >
                      {this.props.selected} In Box
                    </div>
                  ) : null}
                  <div
                    style={{
                      position: "absolute",
                      right: "0.5rem",
                      bottom: "0.25rem",
                      background: "var(--primary-darker)",
                      height: 24,
                      width: 24,
                      borderRadius: "100%",
                    }}
                    className="text-right"
                  >
                    <img src={glutenFree} style={{ width: "24px" }}></img>
                  </div>
                  <div
                    className="meal-info-image"
                    style={{
                      width: "100%",
                      backgroundImage: `url(${
                        this.props.meal.platedImageURL
                          ? this.props.meal.platedImageURL
                          : this.props.meal.imageURL
                      })`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: this.props.meal?.platedImageURL
                        ? "90%"
                        : "120%",
                    }}
                  ></div>
                  <div
                    className={`${
                      this.props.meal?.retired
                        ? "md:grid align-left lg:flex gap-x-2 gap-y-1 md:grid-cols-1 lg:grid-cols-2 items-center"
                        : ""
                    }`}
                    style={{
                      position: "absolute",
                      left: "0.5rem",
                      bottom: "0.5rem",
                    }}
                  >
                    {this.props.meal?.retired ? (
                      <>
                        <div
                          className={`inline-block px-sm-2 bg-yellow-200 border-[1px] border-yellow-900 text-gray-900 font-weight-700 text-uppercase ${
                            !this.props.mobile && "px-1 py-[0.1rem]"
                          }`}
                          style={{
                            fontSize: this.props.mobile ? 10 : "12px",
                            borderRadius: "3px",

                            paddingLeft: this.props.mobile ? "0.2rem" : "",
                            paddingRight: this.props.mobile ? "0.2rem" : "",
                            paddingTop: this.props.mobile ? "0.15rem" : "",
                            paddingBottom: this.props.mobile ? "0.15rem" : "",
                          }}
                        >
                          Limited Time Only
                        </div>
                      </>
                    ) : null}
                    {this.renderTopCategory(this.props.meal)}
                    {this.props.meal?.spicy ? (
                      <div
                        style={{ lineHeight: 1 }}
                        className="inline-block h-[22px] relative left-[0.45rem] top-[7px] sm:ml-0 px-1 border-[1px] border-red-500 bg-red-50 rounded text-gray-900"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="140px"
                          height="185px"
                          viewBox="0 0 140 185"
                          version="1.1"
                          style={{
                            height: 13,
                            width: "auto",
                            position: "relative",
                            top: 3,
                          }}
                        >
                          <title>Meal Heat Flame</title>
                          <g
                            id="Meal-Heat-Flame"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <path
                              d="M107.681558,0.0427138334 C107.659186,0.0643153614 107.637728,0.0869484654 107.617246,0.110547115 C90.7193105,5.68520951 77.1764537,22.2532462 69.8716471,40.7059469 C62.8424875,58.4622994 61.7742218,77.9317946 69.2919377,91.4332384 C58.843956,92.1203807 50.0433588,89.4909601 45.1373211,81.8453565 C39.9927803,73.8280793 38.8693021,60.0655214 45.0084773,38.4619868 C45.11997,38.0538801 44.9963276,37.6145904 44.6918305,37.3369706 C44.3873335,37.0593508 43.9576496,36.994155 43.5914148,37.170005 C9.18969463,55.8789305 -4.47664656,92.8150112 1.27256306,125.092861 C7.02172892,157.370712 32.2868081,185 74.9602098,185 C94.77662,185 111.592939,177.831255 123.140599,164.532188 C134.688259,151.233341 140.928016,131.844035 139.887794,107.685035 C138.843459,80.1224131 125.131181,67.2739748 114.895824,54.5777429 C109.778168,48.2296489 105.567777,41.903777 103.945745,33.770038 C102.323714,25.636321 103.289933,15.5507234 108.905486,1.53852578 C109.072021,1.15188858 109.010228,0.698295065 108.747234,0.376870473 C108.48424,0.0554458807 108.068124,-0.0750500267 107.681558,0.0426697859 L107.681558,0.0427138334 Z"
                              id="Path"
                              fill="#F5365C"
                            />
                          </g>
                        </svg>
                      </div>
                    ) : null}
                  </div>
                </div>
              </IonCardContent>
              <IonCardContent
                className={`px-2 pb-0 pt-2 ${
                  !this.props.checkoutMode && "cursor-pointer"
                }`}
                onClick={() => this.openMealInfo(this.props.meal)}
              >
                <IonRow>
                  <IonCol size-xs="12" className="text-left">
                    <div
                      className={`meal-info-container ${
                        !this.props.checkoutMode && "cursor-pointer"
                      }`}
                    >
                      <h2
                        className="mb-1 meal-info-title text-dark font-weight-bold text-center text-xl-left"
                        style={{ fontSize: 18 }}
                      >
                        {this.props.meal.name}
                      </h2>
                      <div className="meal-info-description text-center text-xl-left">
                        {this.convertToPlain(this.props.meal.description)}
                      </div>

                      {/*<p style={{fontSize: '14px', lineHeight: 1.25}} className="mb-2 text-dark">example description summary goes here with some more words</p>*/}
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
              <IonCardContent className="px-2 pt-0 pb-2">
                <IonRow className="ion-justify-content-center ion-align-items-center">
                  <IonCol size-xs="12" size-xl="auto" className="py-xl-0">
                    <div
                      className={`${
                        !this.props.checkoutMode && "cursor-pointer"
                      }`}
                      onClick={() => this.openMealInfo(this.props.meal)}
                    >
                      <IonRow className="ion-justify-content-center ion-justify-content-xl-left">
                        <IonCol
                          size-xs="auto"
                          className="py-0 pl-0 pr-3 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.calories}
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Cal
                          </p>
                        </IonCol>
                        <IonCol
                          size-xs="auto"
                          className="py-0 pl-0 pr-3 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.protein}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Protein
                          </p>
                        </IonCol>
                        <IonCol
                          size-xs="auto"
                          className="border-right py-0 pl-0 pr-3 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.carbs}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Carbs
                          </p>
                        </IonCol>
                        <IonCol
                          size-xs="auto"
                          className="pl-0 pr-0 py-0 text-center text-xl-left"
                        >
                          <p
                            style={{ fontSize: 16 }}
                            className="text-lowercase text-dark font-weight-bold mb-0 meal-info-nutrition"
                          >
                            {this.props.meal.nutrition.fat}g
                          </p>
                          <p
                            className="text-uppercase text-dark font-weight-bold m-0"
                            style={{ fontSize: "11px" }}
                          >
                            Fats
                          </p>
                        </IonCol>
                      </IonRow>
                    </div>
                  </IonCol>

                  <IonCol className="text-right mt-2 mt-xl-0 pl-xl-3">
                    {this.props.selected > 0 ? (
                      <>
                        <IonGrid className="p-0">
                          <IonRow className="ion-align-items-center">
                            <IonCol size-xs="6" className="pl-0 py-0">
                              <IonButton
                                color="dark"
                                fill="outline"
                                size="small"
                                onClick={() => {
                                  this.props.incrementMeal(this.props.meal, -1);

                                  SegmentUtils.track(
                                    "Product Removed",
                                    this.getSegmentProps()
                                  );
                                }}
                                style={{
                                  height: "32px",
                                }}
                                expand="block"
                                className="m-0"
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={remove}
                                  color={"dark"}
                                ></IonIcon>
                              </IonButton>
                            </IonCol>
                            <IonCol size-xs="6" className="pr-0 py-0">
                              <IonButton
                                color={
                                  this.props.disableAdd ? "plnlight" : "primary"
                                }
                                style={{
                                  height: "32px",
                                }}
                                size="small"
                                disabled={this.props.disableAdd}
                                onClick={() => {
                                  this.props.incrementMeal(this.props.meal, 1);
                                  SegmentUtils.track(
                                    "Product Added",
                                    this.getSegmentProps()
                                  );
                                }}
                                expand="block"
                                className={`${
                                  !this.props.disableAdd && "pln-button-web"
                                } m-0`}
                              >
                                <IonIcon icon={add} slot="icon-only"></IonIcon>
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </>
                    ) : (
                      <IonButton
                        color={this.props.disableAdd ? "plnlight" : "primary"}
                        size="small"
                        disabled={this.props.disableAdd}
                        onClick={() => {
                          this.props.incrementMeal(this.props.meal, 1);
                          SegmentUtils.track(
                            "Product Added",
                            this.getSegmentProps()
                          );
                        }}
                        style={{
                          height: "32px",
                        }}
                        className="pln-button-web m-0"
                        expand="block"
                      >
                        <IonIcon icon={add} slot="start"></IonIcon>Add To Box
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </>
        )}
      </>
    );
  }
}

export default MealOptionCard;
