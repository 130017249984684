import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonToast,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./ResetPassword.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import { eye, eyeOff, navigateCircleOutline } from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import carousel1 from "../../../../assets/img/backgrounds/login_carousel_1-min.jpg";
import carousel2 from "../../../../assets/img/backgrounds/login_carousel_2-min.jpg";
import carousel3 from "../../../../assets/img/backgrounds/login_carousel_3-min.jpg";
import carousel4 from "../../../../assets/img/backgrounds/login_carousel_4-min.jpg";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { Capacitor } from "@capacitor/core";
import StringUtils from "../../../../lib/StringUtils";
import { Link } from "react-router-dom";
import SEOHeaders from "../../../../components/SEOHeaders";

class ResetPassword extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    slide: 1,
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  handleTransition() {
    const interval = setInterval(() => {
      let slide = this.state.slide;

      if (slide == 4) {
        slide = 1;
      } else {
        slide = slide + 1;
      }

      this.setState({
        slide,
      });
    }, 10000);

    this.setState({
      slideInterval: interval,
    });
  }

  componentDidMount(): void {
    this.handleTransition();

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    let token = urlParams.get("token");
    let code = urlParams.get("code");

    if (!token) {
      return this.props.history.push("/login");
    }

    this.setState({
      token,
    });

    if (code) {
      this.setState({
        code,
      });
    }
  }

  componentWillUnmount(): void {
    if (this.state.slideInterval) {
      clearInterval(this.state.slideInterval);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  setError(message: string) {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setError("");
      }, 7000);
    }
  }

  continue() {
    this.setState({
      submitting: true,
    });

    API.setPassword(
      this.state.token,
      this.state.password,
      this.state.code ? this.state.code : null
    )
      .then(
        (data: any) => {
          let resp = data?.data?.data;

          PubSub.publish(Constants.TOAST.SHOW, {
            template: "success",
            message: "Your password has been successfully updated.",
            position: "top",
          });

          this.setState({
            token: "",
            password: "",
          });

          this.props.history.push("/login");
        },
        (e) => {
          console.error(e);

          this.setError(
            `Unable to set your password. ${
              e?.response?.data?.message
                ? e?.response?.data?.message
                : "Unknown error occurred, please try again."
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });

    return;
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders title="Reset Password"></SEOHeaders>
        {Capacitor.isNativePlatform() && (
          <>
            <IonHeader>
              <IonToolbar className="pln-native-toolbar">
                <IonTitle>Reset Password</IonTitle>
              </IonToolbar>
            </IonHeader>
          </>
        )}
        <IonContent>
          <div className="pb-lg-4" style={{}}>
            {/** Desktop View */}
            <div
              className="pln-store-selector-container"
              style={{
                maxWidth: 1200,
                margin: "auto",
                maxHeight: 800,
                overflow: "hidden",
              }}
            >
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol
                    size-xs={12}
                    size-md={6}
                    size-lg={5}
                    className="pt-0 px-0 pb-0 left-col"
                    style={{
                      borderRight: "1px solid var(--lighter)",
                      borderLeft: "1px solid var(--lighter)",
                      background: "var(--white)",
                    }}
                  >
                    <div
                      className="px-4 py-4 text-center d-flex ion-justify-content-center ion-align-items-center"
                      style={{
                        minHeight: this.state.showPassword ? 660 : 600,
                        height: "calc(100vh)",
                        maxHeight: 800,
                      }}
                    >
                      <div>
                        <img
                          src={plnSymbol}
                          alt="Project LeanNation Logo"
                          style={{ height: 60, width: 60 }}
                        ></img>
                        <h1 className="mt-3 mb-0 font-weight-bold">
                          Set Your Password
                        </h1>
                        <p
                          className="small text-medium mt-2"
                          style={{ lineHeight: 1.3 }}
                        >
                          Enter a new password below to update your account.
                          Your new password must be 8+ characters long.
                        </p>
                        <div className="mt-4">
                          <form
                            onSubmit={(e: any) => {
                              e.preventDefault();

                              this.continue();
                            }}
                          >
                            <div className="mt-3">
                              {this.state.error && (
                                <div className="mb-3 text-danger">
                                  {this.state.error}
                                </div>
                              )}

                              <IonItem
                                color="primary"
                                className={`pln-input-web small  mb-3 mb-md-4`}
                                mode="md"
                                fill="outline"
                              >
                                <IonLabel position="floating">
                                  Password
                                </IonLabel>
                                <IonInput
                                  autocapitalize="none"
                                  inputMode="text"
                                  color="primary"
                                  name="password"
                                  type={
                                    this.state.viewPassword
                                      ? "text"
                                      : "password"
                                  }
                                  autocomplete="new-password"
                                  value={this.state.password}
                                  placeholder="Password"
                                  required={true}
                                  clearInput={true}
                                  onIonChange={(event: any) => {
                                    this.setState({
                                      password: event?.detail?.value,
                                    });
                                  }}
                                ></IonInput>
                                <IonButton
                                  slot="end"
                                  fill="clear"
                                  color="medium"
                                  className="mx-0"
                                  onClick={() => {
                                    this.setState({
                                      viewPassword: !this.state.viewPassword,
                                    });
                                  }}
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={
                                      this.state.viewPassword ? eyeOff : eye
                                    }
                                  ></IonIcon>
                                </IonButton>
                              </IonItem>

                              <IonButton
                                type="submit"
                                className="pln-button-web"
                                mode="ios"
                                expand={"block"}
                                color="primary"
                                disabled={
                                  !this.state.password ||
                                  this.state.password?.length < 8 ||
                                  this.state.submitting
                                }
                                onClick={() => {
                                  this.continue();
                                }}
                              >
                                {this.state.submitting ? (
                                  <>
                                    <IonSpinner name="dots"></IonSpinner>
                                  </>
                                ) : (
                                  <>Continue</>
                                )}
                              </IonButton>
                              <button
                                type="submit"
                                style={{
                                  position: "absolute",
                                  visibility: "hidden",
                                }}
                              >
                                submit
                              </button>
                              <div
                                className="mt-3 text-center"
                                style={{
                                  color: "var(--light)",
                                  fontSize: 12,
                                }}
                              >
                                By clicking Continue, "Continue with Google", or
                                "Continue with Apple", you agree to our{" "}
                                <a
                                  href="https://google.com"
                                  target={"_blank"}
                                  rel="noreferrer"
                                  style={{ color: "var(--medium)" }}
                                >
                                  Terms of Service
                                </a>
                                ,{" "}
                                <a
                                  href="https://google.com"
                                  target={"_blank"}
                                  rel="noreferrer"
                                  style={{ color: "var(--medium)" }}
                                >
                                  Privacy Policy
                                </a>
                                .
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol
                    className="d-none d-md-flex p-0 right-col"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel1}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 3 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel2}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 2 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel3}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 4 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel4}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 1 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: "1rem",
                        bottom: "1rem",
                        width: "calc(100%)",
                        maxWidth: 500,
                        paddingRight: "1rem",
                      }}
                    >
                      <div
                        className={`${
                          this.state.slide == 1 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Personalized nutrition plans designed for your goals.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN Ballantyne
                        </p>
                      </div>
                      <div
                        className={`${
                          this.state.slide == 2 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Transforming health with delicious, ready-to-heat
                          meals.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN Plaza
                        </p>
                      </div>
                      <div
                        className={`${
                          this.state.slide == 3 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Coaching &amp; check-ins keep you on track for
                          success.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN Concord
                        </p>
                      </div>
                      <div
                        className={`${
                          this.state.slide == 4 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Flexible plans to fit your lifestyle.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN South End
                        </p>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
          <Footer></Footer>
        </IonContent>
      </IonPage>
    );
  }
}

export default ResetPassword;
