import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./Locations.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import { navigateCircleOutline } from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import SegmentUtils from "../../../../lib/SegmentUtils";
import SEOHeaders from "../../../../components/SEOHeaders";

class LocationsV2 extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  _loadLead(lead: any) {
    if (!lead) {
      return;
    }

    //console.log(lead);

    this.setState({
      lead,
    });

    if (lead?.zipCode) {
      this.setState({
        zipCode: lead?.zipCode,
      });

      //this.submitLocation(lead?.zipCode, false);
    }
  }

  setError(key: any, msg: any) {
    if (!msg) {
      this.setState({
        [key]: "",
      });

      return;
    }

    this.setState({
      [key]: msg,
    });

    setTimeout(() => {
      this.setError(key, "");
    }, 5000);
  }

  componentDidMount(): void {}

  selectStore(store: any) {
    this.props.history.push(`/schedule/${store?._id}/nc-consult`);
  }

  getStoresForZipCode(zipCode: string) {
    if (!this.state.zipCode) {
      this.setError("error", "Please enter a valid zip code to continue.");

      return;
    }

    if (
      parseInt(this.state.zipCode) < 501 ||
      parseInt(this.state.zipCode) > 99950
    ) {
      this.setError("error", "Please enter a valid zip code to continue.");

      return;
    }

    this.setState({
      submitting: true,
      storeOptions: [],
      noStoreFound: false,
      comingSoon: false,
    });

    API.getStoresForZip(zipCode)
      .then(
        (data: any) => {
          let stores = data?.data?.data?.stores?.length
            ? data?.data?.data?.stores
            : [];

          stores = _.filter(stores, (store: any) => {
            return DateUtils.storeCanScheduleConsultations(store);
          });

          this.setState({
            storeOptions: stores,
          });

          if (stores?.length == 0) {
            this.setState({
              noStoreFound: true,
            });
          } else if (stores?.length == 1) {
            this.selectStore(stores[0]);
          }

          console.log(stores);
        },
        (e) => {
          // TODO: handle error with message here
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  render() {
    return (
      <IonPage>
        <SEOHeaders title="Choose A Location"></SEOHeaders>
        <SchedulerNavbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          activeStep={1}
          showBackButton={false}
        ></SchedulerNavbar>
        <IonContent style={{ background: "var(--white)" }}>
          <div
            className="pb-lg-4"
            style={{
              background: "var(--white)",
            }}
          >
            {/** Desktop View */}
            <div
              style={{
                maxWidth: 1200,
                margin: "auto",

                overflow: "hidden",
              }}
              className="px-4 px-sm-6"
            >
              <div className="max-w-[500px] mt-4 mx-auto min-h-[70vh]">
                <p
                  className={`text-gray-900 mb-[0.5rem] text-center text-2xl font-bold`}
                >
                  Schedule Your Free Consultation
                </p>
                <p
                  className={`text-gray-900 text-center text-[17px] leading-5`}
                >
                  Enter your zip code below to schedule a free consultation at a
                  PLN store near you.
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    this.getStoresForZipCode(this.state.zipCode);
                  }}
                >
                  <div className="mt-3 sm:mt-4">
                    <input
                      placeholder="Zip Code"
                      id="frequency"
                      value={this.state.zipCode}
                      onChange={(e) => {
                        this.setState({
                          zipCode: e.target.value
                            .replace(/\D/g, "")
                            .substring(0, 5),
                        });
                      }}
                      className="col-span-2 bg-white text-capitalize border border-gray-100 text-gray-900 text-base rounded-lg outline-0 focus:ring-orange-500 focus:border-orange-500 block w-full p-2 h-[40px] placeholder-gray-400"
                    />
                  </div>
                  <button
                    disabled={this.state.submitting}
                    className={`mt-3 sm:mt-4 w-full font-bold rounded-lg ${
                      this.state.submitting || this.state.zipCode?.length <= 4
                        ? "bg-orange-300"
                        : "bg-orange-500"
                    } shadow-sm text-white outline-0 focus:ring-orange-500 focus:border-orange-500 p-2 h-[40px]`}
                  >
                    {this.state.submitting ? (
                      <IonSpinner name="dots" color="light" />
                    ) : (
                      "Continue"
                    )}
                  </button>
                  {this.state.error ? (
                    <p className="mt-2 text-red-500 text-sm text-center">
                      {this.state.error}
                    </p>
                  ) : null}
                </form>
                {this.state.noStoreFound ? (
                  <>
                    <div className="mt-3">
                      <p className="mt-0 mb-1 text-center">
                        Unfortunately we don't have a store in your area that is
                        scheduling consultations at this time. Enter a different
                        zip code or check back soon.
                      </p>
                    </div>
                  </>
                ) : null}

                {this.state.storeOptions?.length > 1 ? (
                  <>
                    <div className="mt-3">
                      <p className="mt-0 mb-3 text-center">
                        Select the store nearest to you:
                      </p>
                      {this.state.storeOptions?.map((store: any) => (
                        <div
                          onClick={() => {
                            this.selectStore(store);
                          }}
                          className={`p-3 cursor-pointer items-center flex gap-x-3 rounded-lg border border-gray-100 mb-3 ${
                            this.state.storeSubmitting
                              ? "bg-gray-50 text-gray-300"
                              : "text-gray-900"
                          }`}
                          key={store?._id}
                        >
                          <div className="grow">
                            <p className="text-base font-bold">
                              {store?.name
                                ?.replace("Project LeanNation", "PLN")
                                ?.trim()}
                            </p>
                            <p className="text-sm">
                              {store?.location?.address?.formattedAddress}
                            </p>
                          </div>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="h-8 w-8"
                            >
                              <path d="m9 18 6-6-6-6" />
                            </svg>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <Footer bgWhite={true}></Footer>
        </IonContent>
      </IonPage>
    );
  }
}

export default LocationsV2;
