import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonSegment,
  IonSegmentButton,
  IonModal,
  withIonLifeCycle,
} from "@ionic/react";
import { useParams } from "react-router";
import Navbar from "../../../../components/Checkout/Navbar/Navbar";
import ConversionForm from "../../../../components/ConversionForm/ConversionForm";
import "./Box.css";

import background from "../../../../assets/img/backgrounds/bg_meals_superlight_lg.jpg";
import backgroundSM from "../../../../assets/img/backgrounds/bg_meals_superlight_sm.jpg";
import personalized from "../../../../assets/img/icons/personalized.svg";
import coaching from "../../../../assets/img/icons/coaching.svg";
import inbody from "../../../../assets/img/icons/inbody.svg";
import store from "../../../../assets/img/icons/store.svg";

import React, { useMemo } from "react";
import MealTopicSlider from "../../../../components/ConversionForm/MealTopicSlider";
import TestimonialSlider from "../../../../components/ConversionForm/TestimonialSlider";
import FAQQuestions from "../../../../components/FAQQuestions";
import Footer from "../../../../components/Checkout/Footer";
import {
  arrowForwardOutline,
  cart,
  checkmarkSharp,
  chevronForward,
  filter,
  navigateCircleOutline,
} from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _, { first } from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import StringUtils from "../../../../lib/StringUtils";

import ProteinFilterButton from "../../../../components/ProteinFilterButton";
import CategoryFilterButton from "../../../../components/CategoryFilterButton";
import MealOptionCard from "../../../../components/MealOptionCard";
import MealDetailModal from "../../../../components/MealDetailModal";
import SegmentUtils from "../../../../lib/SegmentUtils";
import moment from "moment";
import SEOHeaders from "../../../../components/SEOHeaders";
import MealOptionCardNoSelection from "../../../../components/MealOptionCardNoSelection";
import PostHogUtils from "../../../../lib/PostHogUtils";

class Box extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
    coupon: {
      _id: "1234",
      discountType: "Flat",
      flatDiscount: 4000,
    },
    proteinFilters: [],
    categoryFilters: [],
    proteins: [
      "chicken",
      "beef",
      "pork",
      "turkey",
      "fish",
      "plant",
      "egg",
      "other",
    ],
    plan: null,
    selectedMeals: [],
  };

  pageRef: any = React.createRef();
  boxModalRef: any = React.createRef();
  filterModalRef: any = React.createRef();

  loadMealOptions(store: any, plan: any) {
    if (
      !store ||
      !plan?.planTypeID ||
      !store?.menus?.meals?.hasOwnProperty(plan?.planTypeID)
    ) {
      return;
    }

    let menu = store?.menus?.meals[plan?.planTypeID]?.active;

    let meals = menu?.meals?.length ? menu?.meals : [];

    let categories: any = [];
    let allergens: any = [];

    for (let i = 0; i < meals?.length; i++) {
      const meal = meals[i];

      for (let j = 0; j < meal?.categories?.length; j++) {
        const category = meal?.categories[j];

        if (
          !_.findWhere(categories, { name: category?.name }) &&
          category?.name != "Gluten Free"
        ) {
          categories.push(category);
        }
      }

      for (let j = 0; j < meal?.allergens?.length; j++) {
        const allergen = meal?.allergens[j];

        if (!_.findWhere(allergens, { name: allergen?.name })) {
          allergens.push(allergen);
        }
      }
    }

    let newCat = _.findWhere(categories, { name: "New" });
    let best = _.findWhere(categories, { name: "Best Sellers" });

    let remaining = _.filter(categories, (cat: any) => {
      return cat?.name != "Best Sellers" && cat?.name != "New";
    });

    remaining = _.sortBy(remaining, "name");

    let catOut = [];

    if (best) {
      catOut.push(best);
    }

    catOut = catOut.concat(remaining);

    this.setState(
      {
        allMeals: meals,
        categories: catOut,
        allergens: _.sortBy(allergens, "name"),
      },
      () => {
        this.updateFilteredMeals();
        this.filterAvailableMeals();
      }
    );
  }

  filterAvailableMeals() {
    if (!this.state.selectedMeals?.length) {
      return;
    }

    let selectedMeals = this.state.selectedMeals;

    selectedMeals = _.filter(selectedMeals, (meal: any) => {
      return _.findWhere(this.state.allMeals, { _id: meal?._id });
    });

    this.setState({
      selectedMeals,
    });
  }

  async loadStore() {
    this.setState({
      loadingStore: true,
    });

    const store = await StorageUtils.get("checkout_store");
    const plan = await StorageUtils.get("checkout_plan");
    let selectedMeals = await StorageUtils.get("checkout_meals");

    if (!plan) {
      // TODO: redirect here

      //this.props.history.push("/start/plan");

      return;
    }

    this.setState({
      plan,
    });

    if (store) {
      this.setState(
        {
          store,
        },
        () => {
          this.loadFirstBillDate();
        }
      );
    }

    if (selectedMeals?.length) {
      let selectedQuantity = 0;

      let selectedOut = [];

      let currentQuantity = 0;

      for (let i = 0; i < selectedMeals?.length; i++) {
        const quantity = selectedMeals[i].quantity;

        currentQuantity += quantity;
      }

      for (let i = 0; i < selectedMeals?.length; i++) {
        // console.log("HERE", selectedMeals[i]?.name);

        const quantity = selectedMeals[i].quantity;

        if (selectedQuantity == plan?.quantity) {
          break;
        }

        if (selectedQuantity + quantity <= plan?.quantity) {
          selectedOut.push(selectedMeals[i]);

          selectedQuantity += quantity;

          continue;
        }

        if (currentQuantity > plan?.quantity) {
          const diff = plan?.quantity - selectedQuantity;

          //   console.log("DIFF", diff);

          if (diff >= 0) {
            selectedMeals[i].quantity = diff;

            selectedOut.push(selectedMeals[i]);

            selectedQuantity += diff;
          }
        }
      }

      selectedOut = _.filter(selectedOut, (meal: any) => {
        return meal?.quantity >= 1;
      });

      this.setState({
        selectedMeals: selectedOut,
      });

      await StorageUtils.set("checkout_meals", selectedOut);

      if (this.state.lead) {
        let cartState = this.state.lead?.cartState
          ? this.state.lead.cartState
          : {};

        cartState.step = "menu";
        cartState.meals = selectedOut;

        API.updateLeadCart(this.state.lead, cartState).then(
          async (data: any) => {
            let lead = data?.data?.data?.lead;

            await StorageUtils.set("checkout_lead", lead);
          },
          (e) => {
            console.error(e);
          }
        );
      }
    }

    // TODO: get from lead, fallback to store cache
    // if none, redirect to locations

    const storeID = store?._id;

    API.getStoreByID(storeID)
      .then(
        (data: any) => {
          const store = data?.data?.data?.store;

          this.setState(
            {
              store,
            },
            () => {
              this.loadFirstBillDate();
            }
          );

          this.loadMealOptions(store, plan);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingStore: false,
        });
      });
  }

  async _loadLead(lead: any) {
    console.log("LOAD LEAD", lead);

    if (!lead) {
      return;
    }

    //console.log(lead);

    this.setState({
      lead,
    });

    const store = await StorageUtils.get("checkout_store");
    const plan = await StorageUtils.get("checkout_plan");

    this.setState(
      {
        store,
        plan,
      },
      () => {
        this.loadFirstBillDate();
      }
    );

    this.loadMealOptions(store, plan);
  }

  async loadFirstBillDate() {
    let fbd = await StorageUtils.get("checkout_first_bill_date");

    if (fbd) {
      console.log("FBD", fbd);

      this.setState(
        {
          firstBillDate: fbd,
        },
        () => {
          this.setState({
            firstBillDate: this.getFirstBillingDate(true),
          });
        }
      );
    } else {
      this.setState({
        firstBillDate: this.getFirstBillingDate(true),
      });
    }
  }

  componentDidMount() {
    const windowSize = WindowUtils.getDimensions();

    if (windowSize !== null) {
      const mobile = windowSize?.width < 768;

      this.setState({
        isMobile: mobile,
      });
    }

    this.loadFirstBillDate();

    PubSub.subscribe(Event.WINDOW_RESIZE, (size: any) => {
      if (size?.width) {
        if (this.state.isMobile && size?.width >= 768) {
          this.setState({
            isMobile: false,
          });
        } else if (!this.state.isMobile && size?.width < 768) {
          this.setState({
            isMobile: true,
          });
        }
      }
    });

    StorageUtils.get("checkout_lead").then((lead: any) => {
      if (lead) {
        this._loadLead(lead);
      }
    });

    PubSub.subscribe(Event.CHECKOUT.LEAD_RESTORED, (lead: any) => {
      this._loadLead(lead);
    });

    PubSub.subscribe(Event.CHECKOUT.PLAN_UPDATED, (plan: any) => {
      this.loadStore();
    });

    this.loadStore();
  }

  ionViewDidEnter() {
    PostHogUtils.getFeatureFlag("checkoutMenuOptions").then((value: any) => {
      if (value != "test") {
        this.props.history.replace("/start/box");
      }
    });

    const windowSize = WindowUtils.getDimensions();

    if (windowSize !== null) {
      const mobile = windowSize?.width < 768;

      this.setState({
        isMobile: mobile,
      });
    }

    this.loadFirstBillDate();

    this.loadStore();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  getMarketingName(store: any) {
    if (!store) {
      return "";
    }

    if (store?.type != "retail") {
      return "Delivered To You";
    }

    if (store?.displayCity && store?.displayState) {
      return `${store?.displayCity?.trim()}, ${store?.displayState?.trim()}`;
    }

    return `${store?.location?.address?.city?.trim()}, ${store?.location?.address?.state?.trim()}`;
  }

  filterProteins(protein: any, selected: any) {
    let filters = this.state.proteinFilters;

    if (selected) {
      if (!filters?.includes(protein)) {
        filters.push(protein);
      }
    } else {
      filters = _.filter(filters, (pro) => {
        return pro != protein;
      });
    }

    this.setState(
      {
        proteinFilters: filters,
      },
      () => {
        this.updateFilteredMeals();
      }
    );
  }

  filterCategories(category: any, selected: any) {
    let filters = this.state.categoryFilters?.length
      ? this.state.categoryFilters
      : [];

    if (selected) {
      if (!filters?.includes(category)) {
        filters.push(category);
      }
    } else {
      filters = _.filter(filters, (pro) => {
        return pro != category;
      });
    }

    this.setState(
      {
        categoryFilters: filters,
      },
      () => {
        this.updateFilteredMeals();
      }
    );
  }

  filterAllergens(allergen: any, selected: any) {
    let filters = this.state.allergenFilters?.length
      ? this.state.allergenFilters
      : [];

    if (selected) {
      if (!filters?.includes(allergen)) {
        filters.push(allergen);
      }
    } else {
      filters = _.filter(filters, (pro) => {
        return pro != allergen;
      });
    }

    this.setState(
      {
        allergenFilters: filters,
      },
      () => {
        this.updateFilteredMeals();
      }
    );
  }

  clearFilters() {
    this.setState(
      {
        proteinFilters: [],
        categoryFilters: [],
        allergenFilters: [],
      },
      () => {
        this.updateFilteredMeals();
      }
    );
  }

  async clearSelectedMeals() {
    this.setState({
      selectedMeals: [],
    });

    await StorageUtils.set("checkout_meals", []);
  }

  updateFilteredMeals() {
    if (
      !this.state.proteinFilters?.length &&
      !this.state.categoryFilters?.length &&
      !this.state.allergenFilters?.length
    ) {
      this.setState({
        filteredMeals: this.sortMeals(this.state.allMeals),
      });

      return;
    }

    let proteins = this.state.proteinFilters?.length
      ? this.state.proteinFilters?.map((p: any) => {
          if (p == "chicken") {
            return "Chicken";
          }

          if (p == "beef") {
            return "Beef";
          }

          if (p == "pork") {
            return "Pork";
          }

          if (p == "turkey") {
            return "Turkey";
          }

          if (p == "egg") {
            return "Egg";
          }

          if (p == "fish") {
            return "Fish";
          }

          if (p == "plant") {
            return "Plant Based";
          }

          if (p == "other") {
            return "Lean Cheat";
          }
        })
      : [];

    let categories = this.state.categoryFilters?.length
      ? this.state.categoryFilters
      : [];

    let allergens = this.state.allergenFilters?.length
      ? this.state.allergenFilters
      : [];

    let filteredMeals = _.filter(this.state.allMeals, (meal: any) => {
      if (proteins?.length) {
        // The meal doesn't contain the protein
        if (!proteins.includes(meal?.primaryProteinCategory)) {
          return false;
        }

        if (categories?.length) {
          for (let i = 0; i < categories?.length; i++) {
            const cat = categories[i];

            if (!_.findWhere(meal?.categories, { name: cat })) {
              return false;
            }
          }
        }

        if (allergens?.length) {
          for (let i = 0; i < allergens?.length; i++) {
            const allergen = allergens[i];

            if (_.findWhere(meal?.allergens, { name: allergen })) {
              return false;
            }
          }
        }
      } else {
        for (let i = 0; i < categories?.length; i++) {
          const cat = categories[i];

          if (!_.findWhere(meal?.categories, { name: cat })) {
            return false;
          }
        }

        for (let i = 0; i < allergens?.length; i++) {
          const allergen = allergens[i];

          if (_.findWhere(meal?.allergens, { name: allergen })) {
            return false;
          }
        }
      }

      return true;
    });

    let allFilters = proteins
      .map((p: any) => {
        return { type: "protein", value: p };
      })
      .concat(
        categories.map((p: any) => {
          return { type: "category", value: p };
        })
      )
      .concat(
        allergens.map((p: any) => {
          return { type: "allergen", value: p };
        })
      );

    // console.log(allFilters);

    SegmentUtils.track("Product List Filtered", {
      category: this.state.plan?.planType?.name + " Meals",
      filters: allFilters,
      products: this.sortMeals(filteredMeals).map((meal: any, i: number) => {
        return {
          product_id: meal?._id,
          brand: "Project LeanNation",
          category: meal?.planType?.name + " Meals",
          image_url: meal?.imageURL,
          name: meal?.name,
          sku: meal?.sku,
          url: meal?.deeplinkURL,
          position: i + 1,
        };
      }),
    });

    this.setState({
      filteredMeals: this.sortMeals(filteredMeals),
    });
  }

  getSelectedQuantity() {
    let quantity = 0;

    for (let i = 0; i < this.state.selectedMeals?.length; i++) {
      quantity += this.state.selectedMeals[i]?.quantity
        ? this.state.selectedMeals[i]?.quantity
        : 0;
    }

    return quantity;
  }

  getSelectedQuantityForMeal(meal: any) {
    let m = _.findWhere(this.state.selectedMeals, { _id: meal?._id });

    if (!m) {
      return 0;
    }

    return m?.quantity > 0 ? m?.quantity : 0;
  }

  buildRandomBox() {
    return new Promise((resolve: any) => {
      let meals = this.state.filteredMeals;

      // console.log(meals);

      meals = _.filter(meals, (meal) => {
        return !_.findWhere(meal?.categories, { name: "Lean Cheats" });
      });

      if (!meals?.length) {
        meals = this.state.allMeals;
      }

      function getRandomInt(min: any, max: any) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      // TODO: Fire Firebase here

      let selected: any = [];

      this.setState(
        {
          selectedMeals: [],
        },
        () => {
          for (let i = 0; i < this.state.plan.quantity; i++) {
            const randIdx = getRandomInt(0, meals.length - 1);

            let selectedMeal = _.clone(meals[randIdx]);

            if (_.findWhere(selected, { _id: selectedMeal._id })) {
              this.incrementMeal(selectedMeal, 1, true);
            } else {
              this.incrementMeal(selectedMeal, 1, true);

              selected.push(selectedMeal);
            }
          }

          resolve();
        }
      );
    });
  }

  async incrementMeal(meal: any, quantity: number, autoBuild: boolean = false) {
    let i = _.findIndex(this.state.selectedMeals, { _id: meal._id });

    if (i < 0) {
      let m = _.clone(meal);
      let cats = [];

      for (let i = 0; i < m.categories.length; i++) {
        let c = _.clone(m.categories[i]);

        c.meals = [];

        cats.push(c);
      }

      m.quantity = 1;
      m.categories = cats;

      let meals = this.state.selectedMeals;

      meals.push(m);

      this.setState({
        selectedMeals: meals,
      });

      await StorageUtils.set("checkout_meals", meals);

      let lead = this.state.lead;

      if (lead) {
        let cartState = lead.cartState ? lead.cartState : {};

        cartState.step = "menu";
        cartState.meals = meals;

        API.updateLeadCart(lead, cartState).then(
          async (data: any) => {
            const lead = data?.data?.data?.lead;

            await StorageUtils.set("checkout_lead", lead);
          },
          (e) => {
            console.error(e);
          }
        );
      }

      return;
    }

    if (!autoBuild) {
      this.setState({
        autoBuild: false,
      });
    }

    let meals = this.state.selectedMeals;

    meals[i].quantity = meals[i].quantity + quantity;

    if (meals[i].quantity <= 0) {
      meals = _.filter(meals, (m) => {
        return m._id != meal._id;
      });
    }

    let lead = this.state.lead;

    if (lead) {
      let cartState = lead.cartState ? lead.cartState : {};

      cartState.step = "menu";
      cartState.meals = meals;

      API.updateLeadCart(lead, cartState).then(
        async (data: any) => {
          const lead = data?.data?.data?.lead;

          await StorageUtils.set("checkout_lead", lead);
        },
        (e) => {
          console.error(e);
        }
      );
    }

    this.setState({ selectedMeals: meals });

    await StorageUtils.set("checkout_meals", meals);
  }

  isPreorder() {
    if (this.state.store) {
      let firstBill = moment(
        this.state.store?.operations?.storeOpening?.firstBillDate
      );
      let now = moment();

      if (!now.isAfter(firstBill)) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param asMoment
   * @returns any
   */
  getFirstBillingDate(asMoment = false) {
    let firstBillDate = moment(
      this.state.firstBillDate
        ? this.state.firstBillDate
        : this.state.store?.operations?.storeOpening?.firstBillDate
    );

    let now = moment();

    now.tz("America/New_York");

    // Check to see if an override is present for the first bill date.
    if (this.state.firstBillDate) {
      let overrideMoment = moment(this.state.firstBillDate);
      let fbOverride = overrideMoment.isBefore(now);

      if (
        this.isPreorder() &&
        firstBillDate.isBefore(
          moment(this.state.store?.operations?.storeOpening?.firstBillDate)
        )
      ) {
        firstBillDate = moment(
          this.state.store?.operations?.storeOpening?.firstBillDate
        );

        if (asMoment) {
          return firstBillDate;
        }

        return firstBillDate.format("MMMM DD, YYYY");
      }

      if (this.state.store?.operations?.blackoutDates?.length) {
        while (
          this.state.store?.operations?.blackoutDates.indexOf(
            overrideMoment.format("YYYY-MM-DD")
          ) >= 0
        ) {
          overrideMoment.add(1, "week");
        }
      }

      // Check to see if the overrided date is before the first bill date for the store overall
      if (fbOverride) {
        StorageUtils.set("checkout_first_bill_date", "");
      } else {
        if (asMoment) {
          return overrideMoment;
        }

        return overrideMoment.format("MMMM DD, YYYY");
      }
    }

    if (
      this.isPreorder() &&
      firstBillDate.isBefore(
        moment(this.state.store?.operations?.storeOpening?.firstBillDate)
      )
    ) {
      firstBillDate = moment(
        this.state.store?.operations?.storeOpening?.firstBillDate
      );

      if (asMoment) {
        return firstBillDate;
      }

      return firstBillDate.format("MMMM DD, YYYY");
    }

    let next = moment();

    if (now.day() <= 3 && now.minutes() <= 59 && now.hours() <= 23) {
      next.day(6).startOf("day");
    } else {
      next.add(1, "week").day(6).startOf("day");
    }

    if (this.state.store?.operations?.blackoutDates?.length) {
      while (
        this.state.store?.operations?.blackoutDates.indexOf(
          next.format("YYYY-MM-DD")
        ) >= 0
      ) {
        next.add(1, "week");
      }
    }

    if (asMoment) {
      return next;
    }

    return next.format("MMMM DD, YYYY");
  }

  sortMeals(meals: any) {
    if (!meals?.length) {
      return [];
    }

    let bestSellers = _.filter(meals, (meal: any) => {
      return _.findWhere(meal.categories, { name: "Best Sellers" });
    });

    let newOpts = _.filter(meals, (meal: any) => {
      return (
        _.findWhere(meal.categories, { name: "New" }) &&
        !_.findWhere(meal?.categories, { name: "Lean Cheats" })
      );
    });

    let leanCheats = _.filter(meals, (meal: any) => {
      return _.findWhere(meal.categories, { name: "Lean Cheats" });
    });

    let others = _.filter(meals, (meal: any) => {
      return (
        !_.findWhere(meal.categories, { name: "New" }) &&
        !_.findWhere(meal?.categories, { name: "Lean Cheats" }) &&
        !_.findWhere(meal?.categories, { name: "Best Sellers" })
      );
    });

    leanCheats = _.sortBy(leanCheats, "name");
    newOpts = _.sortBy(newOpts, "name");
    bestSellers = _.sortBy(bestSellers, "name");
    others = _.sortBy(others, "name");

    let out: any = [];

    out = out
      .concat(bestSellers)
      .concat(newOpts)
      .concat(others)
      .concat(leanCheats);

    return out;
  }

  async goToCheckout(chooseLater = false) {
    let meals = [];

    this.setState({
      submitting: true,
      chooseLater,
    });

    if (chooseLater) {
      //  console.log("HERE");

      await this.buildRandomBox();
      meals = this.state.selectedMeals;

      StorageUtils.set("checkout_chooseLater", true);
    } else {
      meals = this.state.selectedMeals;

      StorageUtils.set("checkout_chooseLater", false);
    }

    StorageUtils.set("checkout_meals", meals);

    // TODO: Segment logging here

    const lead = this.state.lead;

    let cartState = lead?.cartState ? lead?.cartState : {};

    cartState.step = "checkout";
    cartState.meals = meals;

    if (lead) {
      API.updateLeadCart(lead, cartState).then(
        async (data: any) => {
          const lead = data?.data?.data?.lead;

          await StorageUtils.set("checkout_lead", lead);
        },
        (e) => {
          console.error(e);
        }
      );
    }

    this.setState({
      submitting: false,
    });

    this.props.history.push("/start/checkout");
  }

  render() {
    return (
      <IonPage ref={this.pageRef}>
        <SEOHeaders title="Select Meals"></SEOHeaders>
        <Navbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          activeStep={3}
          showBackButton={true}
          store={this.state.store}
          onGoBack={() => {
            this.props.history.push("/start/plans");
          }}
        ></Navbar>
        <IonContent>
          <div className="pb-lg-4" style={{}}>
            <div className="pb-0 pb-lg-0">
              <div style={{ maxWidth: 1200, margin: "auto" }}>
                <IonGrid className="pt-0">
                  <IonRow>
                    <IonCol className="pt-0 pt-md-1" size-xs={12} size-lg={12}>
                      <div>
                        <div className="mt-0 mx--2 mt-sm-3 mx-md-0">
                          <div className="md:p-4 md:px-5 md:bg-white md:shadow md:rounded-lg md:border-[1px] md:border-gray-100 lg:sticky lg:z-[999] lg:top-[-210px]">
                            <div className="pl-2 pr-3 ml-1 ml-md-0 pr-md-0 pl-md-0 pb-1 pb-md-2">
                              <h2 className="mb-0 pt-3 font-extrabold text-2xl sm:text-3xl md:text-3xl">
                                Tasty Options For Everyone
                              </h2>
                            </div>
                            <div className="grid md:grid-cols-2  md:gap-x-6 lg:gap-x-12 md:items-start">
                              <div className="pl-2 pr-3 ml-1 ml-md-0 pr-md-0 pl-md-0 pb-1 pb-md-2">
                                <p
                                  style={{ lineHeight: 1.4 }}
                                  className="xs:mt-1 hidden md:block mt-[0.5rem] sm:mt-3 md:px-0 text-gray-900 sm:text-lg sm:mx-auto"
                                >
                                  Make health easy with our delicious, dietitian
                                  approved meals that are perfectly portioned to
                                  deliver results.
                                </p>
                                <p
                                  style={{ lineHeight: 1.4 }}
                                  className="block md:hidden xs:mt-1 mt-[0.5rem] sm:mt-3 md:px-0 text-gray-900 sm:text-lg sm:mx-auto"
                                >
                                  Make health easy with our delicious, dietitian
                                  approved meals. Choose from a wide variety of
                                  meals on our rotating menu.
                                </p>
                                <div className="hidden md:block mt-3 text-base sm:text-lg mx-auto">
                                  Our rotating menu offers a variety of
                                  carefully crafted meals with Chicken, Beef,
                                  Turkey, Pork, and more!
                                </div>
                                <div className="hidden md:block mt-3 text-base sm:text-lg mx-auto lg:mb-4 font-bold">
                                  You'll be able to choose meals from our
                                  current menu after you place your order:
                                </div>

                                <div className="hidden lg:block z-[99]">
                                  <button
                                    className="pln-button-web h-[45px] grow bg-orange-500 hover:bg-orange-700 font-bold transition-all px-4 py-1 leading-[17px] rounded-lg text-white  disabled-light block"
                                    disabled={this.state.submitting}
                                    onClick={() => {
                                      this.goToCheckout(true);

                                      SegmentUtils.track("Button Clicked", {
                                        type: "button",
                                        color: "primary",
                                        fill: "outline",
                                        text: "Checkout",
                                        id: "box_checkout_button",
                                      });
                                    }}
                                  >
                                    {this.state.submitting ? (
                                      <>
                                        <IonSpinner name="dots"></IonSpinner>
                                      </>
                                    ) : (
                                      <>
                                        <div className="flex items-center gap-x-1">
                                          <div
                                            className="font-bold text-lg"
                                            style={{ lineHeight: 1.2 }}
                                          >
                                            Continue To Checkout
                                          </div>

                                          <div>
                                            <IonIcon
                                              className="h-5 w-5"
                                              style={{
                                                lineHeight: 1,
                                                position: "relative",
                                                top: 1,
                                              }}
                                              icon={chevronForward}
                                            ></IonIcon>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </button>
                                </div>
                              </div>
                              <div className="grid px-[0.75rem] md:px-0 gap-y-2 mt-[1rem] mb-[0rem]">
                                <div className="flex items-center gap-x-3">
                                  <div className="text-gray-900 text-base sm:text-lg">
                                    ✅
                                  </div>
                                  <div
                                    style={{ lineHeight: 1.3 }}
                                    className="text-gray-900 text-base tracking-6 sm:text-lg"
                                  >
                                    <span className="block md:hidden">
                                      Options for all diets &amp; lifestyles!
                                    </span>
                                    <span className="hidden md:block">
                                      For All Lifestyles: Dairy Free, Grain
                                      Free, and more!
                                    </span>
                                  </div>
                                </div>

                                <div className="items-center gap-x-3 hidden sm:flex">
                                  <div className="text-gray-900 text-base sm:text-lg">
                                    ✅
                                  </div>
                                  <div
                                    style={{ lineHeight: 1.3 }}
                                    className="text-gray-900 text-base sm:text-lg"
                                  >
                                    Prepared by expert chefs for optimal
                                    nutrition & flavor
                                  </div>
                                </div>
                                <div className="flex items-center gap-x-3">
                                  <div className="text-gray-900 text-base sm:text-lg">
                                    ✅
                                  </div>
                                  <div
                                    style={{ lineHeight: 1.3 }}
                                    className="text-gray-900 text-base sm:text-lg"
                                  >
                                    All meals are Gluten Free.
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="block md:hidden mt-3 text-base sm:text-lg px-[0.75rem] md:max-w-[600px] mx-auto mb-4">
                              <span className="font-bold">
                                You'll be able to choose meals from our current
                                menu after you place your order:
                              </span>
                            </div>
                            <div className="block lg:hidden px-[0.75rem] sm:px-4 md:px-0 mt-3 mb-[1.75rem] md:mb-[0.25rem] flex items-center gap-x-[0.75rem] ">
                              <button
                                className="pln-button-web h-[45px] grow bg-orange-500 hover:bg-orange-700 font-bold transition-all px-3 py-1 leading-[17px] rounded-lg text-white  disabled-light block"
                                disabled={this.state.submitting}
                                onClick={() => {
                                  this.goToCheckout(true);

                                  SegmentUtils.track("Button Clicked", {
                                    type: "button",
                                    color: "primary",
                                    fill: "outline",
                                    text: "Checkout",
                                    id: "box_checkout_button",
                                  });
                                }}
                              >
                                {this.state.submitting ? (
                                  <>
                                    <IonSpinner name="dots"></IonSpinner>
                                  </>
                                ) : (
                                  <>
                                    <span className="font-bold text-lg">
                                      Continue To Checkout
                                    </span>
                                  </>
                                )}
                              </button>
                            </div>
                          </div>

                          <div className="sm:mt-6 md:mt-6 grid px-2 px--0 grid-cols-1 md:grid-cols-3 gap-3 md:gap-5">
                            {this.state.filteredMeals?.map(
                              (meal: any, i: any) => (
                                <MealOptionCardNoSelection
                                  position={i + 1}
                                  modalID="webapp-checkout-box"
                                  incrementMeal={this.incrementMeal.bind(this)}
                                  meal={meal}
                                  selected={this.getSelectedQuantityForMeal(
                                    meal
                                  )}
                                  disableAdd={
                                    this.getSelectedQuantity() ==
                                    this.state.plan.quantity
                                  }
                                  mobile={this.state.isMobile}
                                ></MealOptionCardNoSelection>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>

            {/** Desktop View */}
          </div>
          <Footer bgWhite={false}></Footer>
          <IonModal
            ref={this.boxModalRef}
            trigger="open-boxmodal"
            canDismiss={true}
            mode="ios"
          >
            <IonHeader>
              <IonToolbar className="box-modal-toolbar-web">
                <IonButtons slot="start">
                  <IonButton
                    color="danger"
                    onClick={() => {
                      this.clearSelectedMeals();

                      SegmentUtils.track("Button Clicked", {
                        type: "button",
                        color: "danger",
                        fill: "clear",
                        text: "Clear All",
                        id: "box_clear_all_meals_button",
                      });
                    }}
                  >
                    Clear All
                  </IonButton>
                </IonButtons>
                <IonTitle>
                  Selected{" "}
                  {this.getSelectedQuantity()
                    ? `(${this.getSelectedQuantity()}/${
                        this.state.plan?.quantity
                      })`
                    : ""}
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    color="dark"
                    onClick={() => {
                      this.boxModalRef?.current?.dismiss();
                    }}
                  >
                    Done
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              {this.state.selectedMeals?.length ? (
                <>
                  <div className="pt-2 pt-sm-3"></div>
                  {this.state.selectedMeals?.map((meal: any, i: any) => (
                    <div
                      className={`mx-sm-3 mb-sm-3 mx-2 mb-2 ${
                        i == this.state.selectedMeals?.length - 1 && "mb-5"
                      }`}
                      style={{
                        borderLeft: "1px solid var(--lighter)",
                        borderRight: "1px solid var(--lighter)",
                        borderTop: "1px solid var(--lighter)",
                        borderBottom: "1px solid var(--lighter)",
                        borderRadius: 6,
                        overflow: "hidden",
                      }}
                      key={meal?._id}
                    >
                      <MealOptionCard
                        modalID="webapp-checkout-box"
                        incrementMeal={this.incrementMeal.bind(this)}
                        meal={meal}
                        selected={this.getSelectedQuantityForMeal(meal)}
                        disableAdd={
                          this.getSelectedQuantity() == this.state.plan.quantity
                        }
                        mobile={true}
                        compact={true}
                      ></MealOptionCard>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div
                    className="border mt-3 mb-3 mx-3 p-3 text-center d-flex ion-align-items-center"
                    style={{
                      borderRadius: 6,
                      height: "calc(100% - 2rem)",
                    }}
                  >
                    <div>
                      <h1
                        style={{ fontSize: 18 }}
                        className="text-dark mb-2 font-weight-500"
                      >
                        Your Box Is Empty
                      </h1>
                      <p className="m-0 text-dark">
                        Living well starts with good food. Get started by
                        choosing the meals for your first order.
                      </p>
                    </div>
                  </div>
                </>
              )}
            </IonContent>
          </IonModal>
          <IonModal
            ref={this.filterModalRef}
            trigger="open-filtermodal"
            canDismiss={true}
            mode="ios"
          >
            <IonHeader>
              <IonToolbar className="box-modal-toolbar-web">
                <IonButtons slot="start">
                  <IonButton
                    color="danger"
                    onClick={() => {
                      this.clearFilters();

                      SegmentUtils.track("Button Clicked", {
                        type: "button",
                        color: "danger",
                        fill: "clear",
                        text: "Clear All",
                        id: "box_clear_all_filters_button",
                      });
                    }}
                    disabled={
                      !this.state.categoryFilters?.length &&
                      !this.state.allergenFilters?.length &&
                      !this.state.proteinFilters?.length
                    }
                  >
                    Clear All
                  </IonButton>
                </IonButtons>
                <IonTitle>Filter Menu</IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    color="primary"
                    onClick={() => {
                      this.filterModalRef?.current?.dismiss();

                      this.setState({
                        filterOpen: false,
                      });
                    }}
                  >
                    Done
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="px-2">
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                  className="pt-2"
                >
                  <IonGrid className="p-0">
                    <IonRow className="ion-align-items-center">
                      <IonCol size-xs="">
                        <h3
                          className="text-dark font-weight-bold m-0"
                          style={{ fontSize: 18 }}
                        >
                          Filter By Proteins
                        </h3>
                        <p style={{ fontSize: 14 }} className="mb-2 mt-1">
                          Select all your favorite protein sources.
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div>
                  <IonGrid className="p-0">
                    <IonRow className="ion-align-items-center ion-justify-content-center">
                      {this.state.proteins?.map((protein: any, i: any) => (
                        <IonCol
                          size-xs="4"
                          size-sm="4"
                          size-md="3"
                          size-lg="3"
                          key={protein}
                        >
                          <ProteinFilterButton
                            selected={this.state.proteinFilters?.includes(
                              protein
                            )}
                            onClick={this.filterProteins.bind(this)}
                            icon={protein}
                          ></ProteinFilterButton>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
                <hr
                  className="mb-1 mt-3"
                  style={{
                    borderBottom: "1px solid var(--midlighter)",
                  }}
                ></hr>
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                  }}
                  className="pt-2"
                >
                  <IonGrid className="p-0">
                    <IonRow className="ion-align-items-center">
                      <IonCol size-xs="">
                        <h3
                          className="text-dark font-weight-bold m-0"
                          style={{ fontSize: 18 }}
                        >
                          Filter By Categories
                        </h3>
                        <p style={{ fontSize: 14 }} className="mb-2 mt-1">
                          Select one or more categories.
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div>
                  <IonGrid className="p-0">
                    <IonRow className="ion-align-items-center">
                      {this.state.categories?.map((category: any, i: any) => (
                        <IonCol size-xs="auto" key={category?.name}>
                          <CategoryFilterButton
                            selected={this.state.categoryFilters?.includes(
                              category?.name
                            )}
                            onClick={this.filterCategories.bind(this)}
                            category={category?.name}
                          ></CategoryFilterButton>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
                <hr
                  className="mb-1 mt-3"
                  style={{
                    borderBottom: "1px solid var(--midlighter)",
                  }}
                ></hr>
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                  }}
                  className="pt-2"
                >
                  <IonGrid className="p-0">
                    <IonRow className="ion-align-items-center">
                      <IonCol size-xs="">
                        <h3
                          className="text-dark font-weight-bold m-0"
                          style={{ fontSize: 18 }}
                        >
                          Filter By Allergens
                        </h3>
                        <p style={{ fontSize: 14 }} className="mb-2 mt-1">
                          Select one or more allergens you'd like to avoid.
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
                <div className="mb-3">
                  <IonGrid className="p-0">
                    <IonRow className="ion-align-items-center">
                      {this.state.allergens?.map((allergen: any, i: any) => (
                        <IonCol size-xs="auto" key={allergen?.name}>
                          <CategoryFilterButton
                            selected={this.state.allergenFilters?.includes(
                              allergen?.name
                            )}
                            onClick={this.filterAllergens.bind(this)}
                            category={allergen?.name}
                            prefix={"No "}
                          ></CategoryFilterButton>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              </div>
            </IonContent>
          </IonModal>
          <MealDetailModal
            id="webapp-checkout-box"
            presentingElement={this.pageRef}
          ></MealDetailModal>
        </IonContent>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Box);
