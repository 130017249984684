import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonSegment,
  IonSegmentButton,
  IonModal,
} from "@ionic/react";
import { useParams } from "react-router";
import Navbar from "../../../../components/Checkout/Navbar/Navbar";
import ConversionForm from "../../../../components/ConversionForm/ConversionForm";
import "./CheckoutComplete.css";

import background from "../../../../assets/img/backgrounds/bg_meals_superlight_lg.jpg";
import backgroundSM from "../../../../assets/img/backgrounds/bg_meals_superlight_sm.jpg";
import personalized from "../../../../assets/img/icons/personalized.svg";
import coaching from "../../../../assets/img/icons/coaching.svg";
import inbody from "../../../../assets/img/icons/inbody.svg";
import store from "../../../../assets/img/icons/store.svg";

import React, { useMemo } from "react";
import MealTopicSlider from "../../../../components/ConversionForm/MealTopicSlider";
import TestimonialSlider from "../../../../components/ConversionForm/TestimonialSlider";
import FAQQuestions from "../../../../components/FAQQuestions";
import Footer from "../../../../components/Checkout/Footer";
import {
  arrowForwardOutline,
  checkmarkCircle,
  chevronForward,
  chevronForwardCircle,
  closeOutline,
  eye,
  eyeOff,
  navigateCircleOutline,
  timeOutline,
} from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import StringUtils from "../../../../lib/StringUtils";
import moment from "moment";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import SchedulingCalendar from "../../../../components/Scheduler/SchedulingCalendar/SchedulingCalendar";
import SchedulingHours from "../../../../components/Scheduler/SchedulingHours/SchedulingHours";
import InlineScheduler from "../../../../components/Scheduler/SchedulingCalendar/InlineScheduler";
import InlineSchedulingModal from "../../../../components/Scheduler/SchedulingCalendar/InlineSchedulingModal";
import SEOHeaders from "../../../../components/SEOHeaders";
import LinkUtils from "../../../../lib/LinkUtils";

class CheckoutComplete extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
    coupon: null,
    loadingStore: true,
  };

  pageRef: any = React.createRef();
  addressModalRef: any = React.createRef();
  passwordModalRef: any = React.createRef();
  consultModalRef: any = React.createRef();

  async loadStore() {
    const store = await StorageUtils.get("checkout_store");

    if (!this.state.lead || this.state.lead?.storeID != store?._id) {
      this.setState({
        loadingStore: true,
      });
    }

    if (store) {
      this.setState({
        store,
      });
    }

    // TODO: get from lead, fallback to store cache
    // if none, redirect to locations

    const storeID = store?._id;

    API.getStoreByID(storeID)
      .then(
        (data: any) => {
          const store = data?.data?.data?.store;

          this.setState({
            store,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          loadingStore: false,
        });
      });
  }

  _loadLead(lead: any) {
    if (!lead) {
      return;
    }

    this.setState({
      lead,
    });
  }

  componentDidMount() {
    StorageUtils.get("checkout_lead").then((lead: any) => {
      if (lead) {
        this._loadLead(lead);
      } else {
        this.props.history.push("/start");
      }
    });

    StorageUtils.get("checkout_chooseLater").then((chooseLater: any) => {
      if (chooseLater) {
        this.setState({
          chooseLater,
        });
      }
    });

    StorageUtils.get("checkout_name").then((name: any) => {
      if (name) {
        this.setState({
          name,
        });
      }
    });

    StorageUtils.get("checkout_plan").then((plan: any) => {
      if (plan) {
        this.setState({
          plan,
        });
      }
    });

    StorageUtils.get("checkout_firstBillDate").then((fbd: any) => {
      if (fbd) {
        //  console.log(fbd);

        this.setState({
          firstBillDate: moment(fbd),
        });
      }
    });

    this.loadStore();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  getPrice(plan: any) {
    if (!plan) {
      return null;
    }

    let cents = plan?.unitPrice + (plan?.deliveryFee ? plan?.deliveryFee : 0);

    cents = cents / plan?.quantity;

    return StringUtils.centsToCurrency(cents);
  }

  setError(key: string, value: string = "") {
    this.setState({
      errors: {
        ...this.state.errors,
        [key]: value,
      },
    });

    setTimeout(() => {
      this.setError(key, "");
    }, 5000);
  }

  setAccountPassword() {
    this.setState({
      passwordSubmitting: true,
    });

    API.setPasswordNewAccount(this.state.lead?.email, this.state.password)
      .then(
        (data: any) => {
          this.setState({
            passwordComplete: true,
          });

          this.passwordModalRef?.current?.dismiss();
        },
        (e: any) => {
          console.error(e);

          this.setError(
            "password",
            `There was an error setting your password.${
              e?.response?.data?.message
                ? ` ${e?.response?.data?.message}`
                : " Unknown error occurred, please try again."
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          passwordSubmitting: false,
        });
      });
  }

  getMarketingName(store: any) {
    if (!store) {
      return "";
    }

    if (store?.type != "retail") {
      return "Delivered To You";
    }

    if (store?.displayCity && store?.displayState) {
      return `${store?.displayCity?.trim()}, ${store?.displayState?.trim()}`;
    }

    return `${store?.location?.address?.city?.trim()}, ${store?.location?.address?.state?.trim()}`;
  }

  getFirstBillingCutOffDate() {
    if (!this?.state?.firstBillDate) {
      return null;
    }

    const fbd: any = moment(this.state.firstBillDate?.toDate());

    return fbd.day(3).startOf("day");
  }

  render() {
    return (
      <IonPage ref={this.pageRef}>
        <Navbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          onGoBack={() => {
            //this.props.history.push("/start/locations");
          }}
          activeStep={5}
          showBackButton={false}
          store={this.state.store}
          allowNavigation={false}
        ></Navbar>
        <IonContent style={{ background: "var(--white)" }}>
          <div
            className="pb-lg-4"
            style={{
              background: "var(--white)",
            }}
          >
            <div className="pb-0 pb-lg-0">
              <div
                style={{
                  maxWidth: 900,
                  margin: "auto",
                  overflow: "hidden",
                }}
              >
                <IonGrid className="p-0">
                  <IonRow>
                    <IonCol
                      size-xs={12}
                      size-md={12}
                      className="pt-0 px-0 pb-0 left-col"
                      style={{
                        background: "var(--white)",
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      <div className="px-4 pt-5 text-center">
                        <h1 className="display-3 mt-0 mb-0">
                          Welcome To The Project LeanNation Community
                          {StringUtils.getFirstName(this.state.name)
                            ? `, ${StringUtils.getFirstName(this.state.name)}!`
                            : "!"}
                        </h1>
                        <p className="lead-sm mt-2">
                          Your coach at{" "}
                          {this.state.store?.name?.trim()
                            ? this.state.store?.name?.trim()
                            : "Project LeanNation"}{" "}
                          is here to guide you along this new and exciting path,
                          and you have an entire community rooting for you!
                        </p>

                        <IonCard
                          mode="ios"
                          className="mx-0 mb-4 mt-5 pln-card-web"
                          style={{}}
                        >
                          <IonCardContent>
                            <IonRow className="ion-align-items-center">
                              <IonCol
                                size-xs="12"
                                size-md="6"
                                className="py-0 px-0"
                              >
                                <h2 className="text-dark font-weight-500 mb-3">
                                  {this.state.plan?.planType?.name} Meal Plan
                                  Summary:
                                </h2>
                                <h1
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 20 }}
                                >
                                  {this.state.plan?.quantity} Meals /{" "}
                                  {this.state.plan?.frequency == "weekly"
                                    ? "Week"
                                    : "2 Weeks"}
                                </h1>
                                <p className="text-dark">
                                  <span className="text-capitalize">
                                    {this.state.plan?.procurement}
                                  </span>
                                  &nbsp;
                                  {this.state.plan?.procurement == "pickup"
                                    ? "at"
                                    : "from"}
                                  &nbsp;
                                  {this.state.store?.name
                                    ?.replace("Project LeanNation", "PLN")
                                    ?.trim()}
                                </p>
                              </IonCol>

                              <IonCol
                                size-xs="12"
                                size-md="6"
                                className="py-0 px-0"
                              >
                                <div
                                  style={{
                                    borderBottom: "1px solid var(--midlighter)",
                                  }}
                                  className="my-3 d-md-none"
                                ></div>
                                <h2 className="text-dark font-weight-500 mb-3">
                                  First{" "}
                                  <span className="text-capitalize">
                                    {this.state.plan?.procurement}
                                  </span>{" "}
                                  Ready:
                                </h2>
                                <h1
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 20 }}
                                >
                                  {this.state.firstBillDate
                                    ? this.state.firstBillDate?.format(
                                        "MMMM DD, YYYY"
                                      )
                                    : "(check email)"}
                                </h1>
                                <p className="text-dark">
                                  Plan &amp; Meal Changes by{" "}
                                  {this.getFirstBillingCutOffDate()?.format(
                                    "MM/DD"
                                  )}{" "}
                                  11:59PM
                                </p>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                      </div>
                      <div className="px-4 pt-4 text-center">
                        <h1 className="display-3 mt-0 mb-0">Next Steps</h1>
                        <p className="lead-sm mt-2 mb-5">
                          Schedule your new client consultation &amp; finish
                          setting up your Project LeanNation account.
                        </p>
                        <IonCard
                          mode="ios"
                          className="mx-0 mb-4 mt-5 text-left"
                          style={{
                            boxShadow: "0 4px 7px 0 rgba(0,0,0,0.05)",
                          }}
                          color={"primary"}
                          onClick={() => {
                            LinkUtils.openInNewTab(
                              "https://pln.fit/e/download"
                            );
                          }}
                        >
                          <IonCardContent>
                            <IonRow className="ion-align-items-center">
                              <IonCol size-xs="" className="py-0 px-0">
                                <h1
                                  className="font-weight-bold text-white"
                                  style={{ fontSize: 20 }}
                                >
                                  Download The PLN App
                                </h1>
                                <p
                                  className="text-white"
                                  style={{ fontSize: "1.1rem" }}
                                >
                                  Download the PLN app on your iPhone or Android
                                  device.
                                </p>
                              </IonCol>
                              <IonCol size-xs="auto" className="pl-2 pr-0 py-0">
                                <h1 className="text-white m-0">
                                  <IonIcon
                                    icon={chevronForward}
                                    style={{ position: "relative", top: 6 }}
                                  ></IonIcon>
                                </h1>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                        {!this.state.lead?.googleAccount &&
                        !this.state.lead?.appleAccount ? (
                          <IonCard
                            mode="ios"
                            className="mx-0 mb-4 mt-4 pln-card-web text-left"
                            style={{
                              boxShadow: "0 4px 7px 0 rgba(0,0,0,0.05)",
                            }}
                            button={!this.state.passwordComplete}
                            onClick={() => {
                              if (this.state.passwordComplete) {
                                return;
                              }

                              this.passwordModalRef?.current?.present();
                            }}
                          >
                            <IonCardContent>
                              <IonRow className="ion-align-items-center">
                                <IonCol size-xs="" className="py-0 px-0">
                                  <h1
                                    className="font-weight-bold text-dark"
                                    style={{ fontSize: 20 }}
                                  >
                                    Set Up Your Account
                                  </h1>
                                  <p className="lead-sm mt-2">
                                    Add a password to manage your subscription
                                    online &amp; in-app.
                                  </p>
                                </IonCol>
                                <IonCol
                                  size-xs="auto"
                                  className="pl-2 pr-0 py-0"
                                >
                                  <h1 className="text-dark m-0">
                                    <IonIcon
                                      icon={
                                        this.state.passwordComplete
                                          ? checkmarkCircle
                                          : chevronForward
                                      }
                                      style={{ position: "relative", top: 6 }}
                                    ></IonIcon>
                                  </h1>
                                </IonCol>
                              </IonRow>
                            </IonCardContent>
                          </IonCard>
                        ) : null}

                        {/**
                         * <IonCard
                          mode="ios"
                          className="mx-0 mb-4 mt-4 pln-card-web text-left"
                          style={{
                            boxShadow: "0 4px 7px 0 rgba(0,0,0,0.05)",
                          }}
                          button={true}
                          onClick={() => {
                            StorageUtils.set("checkout_lead", null);
                            StorageUtils.set("checkout_coupon", null);
                            StorageUtils.set("checkout_firstBillDate", null);
                            StorageUtils.set("checkout_first_bill_date", null);
                            StorageUtils.set("checkout_plan", null);
                            StorageUtils.set("checkout_store", null);
                            StorageUtils.set("checkout_email", null);
                            StorageUtils.set("checkout_phone", null);

                            sessionStorage.setItem(
                              "checkout_show_coupon",
                              "true"
                            );

                            this.props.history.push("/manage");
                          }}
                        >
                          <IonCardContent>
                            <IonRow className="ion-align-items-center">
                              <IonCol size-xs="" className="py-0 px-0">
                                <h1
                                  className="font-weight-bold text-dark"
                                  style={{ fontSize: 20 }}
                                >
                                  {this.state.chooseLater
                                    ? "Select Your Meals"
                                    : "Login Online"}
                                </h1>
                                <p className="lead-sm mt-2">
                                  Login to your new Project LeanNation account
                                  {this.state.chooseLater
                                    ? " and finish selecting your meals"
                                    : ""}
                                  .
                                </p>
                              </IonCol>
                              <IonCol size-xs="auto" className="pl-2 pr-0 py-0">
                                <h1 className="text-dark m-0">
                                  <IonIcon
                                    icon={chevronForward}
                                    style={{ position: "relative", top: 6 }}
                                  ></IonIcon>
                                </h1>
                              </IonCol>
                            </IonRow>
                          </IonCardContent>
                        </IonCard>
                         */}
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            {/** Desktop View */}
          </div>
          <IonModal
            ref={this.addressModalRef}
            trigger="complete-addressModal"
            canDismiss={true}
            mode="ios"
          >
            <IonHeader>
              <IonToolbar
                className="box-modal-toolbar-web"
                style={{ borderBottom: "none" }}
              >
                <IonTitle>Billing Address</IonTitle>
                <IonButtons slot="start">
                  <IonButton
                    color="dark"
                    onClick={() => {
                      this.addressModalRef?.current?.dismiss();

                      this.setState({
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zipCode: "",
                      });
                    }}
                  >
                    Close
                  </IonButton>
                </IonButtons>
                <IonButtons slot="end">
                  <IonButton disabled={true} color="primary" onClick={() => {}}>
                    {this.state.addressSubmitting ? (
                      <IonSpinner name="dots" />
                    ) : (
                      "Save"
                    )}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent></IonContent>
          </IonModal>
          <IonModal
            ref={this.passwordModalRef}
            trigger="complete-passwordModal"
            canDismiss={true}
            mode="ios"
          >
            <IonHeader>
              <IonToolbar
                className="box-modal-toolbar-web"
                style={{ borderBottom: "none" }}
              >
                <IonTitle>Add Password</IonTitle>
                <IonButtons slot="start">
                  <IonButton
                    color="dark"
                    onClick={() => {
                      this.passwordModalRef?.current?.dismiss();

                      this.setState({
                        password: "",
                      });
                    }}
                  >
                    Close
                  </IonButton>
                </IonButtons>
                <IonButtons slot="end">
                  <IonButton
                    disabled={
                      this.state.passwordSubmitting ||
                      !this.state.password ||
                      this.state.password?.length < 8
                    }
                    color="primary"
                    onClick={() => {
                      this.setAccountPassword();
                    }}
                  >
                    {this.state.passwordSubmitting ? (
                      <IonSpinner name="dots" />
                    ) : (
                      "Save"
                    )}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="ion-padding">
                <p className="text-dark mb-4 mt-2 font-weight-500">
                  Add a password to your Project LeanNaton account to manage
                  your subscription online &amp; in-app. Your password must be
                  8+ characters long.
                </p>
                <IonItem
                  color="primary"
                  className={`pln-input-web `}
                  mode="md"
                  fill="outline"
                >
                  <IonLabel position="floating">New Password</IonLabel>
                  <IonInput
                    autocapitalize="none"
                    inputMode="text"
                    color="primary"
                    name="password"
                    type={this.state.viewPassword ? "text" : "password"}
                    autocomplete="new-password"
                    value={this.state.password}
                    placeholder="New Password"
                    required={true}
                    clearInput={true}
                    onIonChange={(event: any) => {
                      this.setState({
                        password: event?.detail?.value,
                      });
                    }}
                  ></IonInput>
                  <IonButton
                    slot="end"
                    fill="clear"
                    color="medium"
                    onClick={() => {
                      this.setState({
                        viewPassword: !this.state.viewPassword,
                      });
                    }}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={this.state.viewPassword ? eyeOff : eye}
                    ></IonIcon>
                  </IonButton>
                </IonItem>
                {this.state.errors?.password ? (
                  <>
                    <p className="text-danger mt-2 mb-0">
                      {this.state.errors?.password}
                    </p>
                  </>
                ) : null}
              </div>
            </IonContent>
          </IonModal>
          <InlineSchedulingModal
            store={this.state.store}
            presentingElement={this.pageRef?.current}
            appointmentType={Constants.APPOINTMENT_TYPES["nc-consult"]}
            forceType={true}
            forceStack={false}
            fields={["goal", "notes"]}
            requiredFields={["goal"]}
            lead={this.state.lead}
          ></InlineSchedulingModal>

          <Footer bgWhite={true}></Footer>
        </IonContent>
      </IonPage>
    );
  }
}

export default CheckoutComplete;
