import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./Availability.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import { navigateCircleOutline, timeOutline } from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import SchedulingCalendar from "../../../../components/Scheduler/SchedulingCalendar/SchedulingCalendar";
import SchedulingHours from "../../../../components/Scheduler/SchedulingHours/SchedulingHours";
import SEOHeaders from "../../../../components/SEOHeaders";
import SchedulingCalendarV2 from "../../../../components/Scheduler/SchedulingCalendar/SchedulingCalendarV2";

class Availability extends React.Component<{ history: any; match: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  loadStore() {
    if (!this.state.store) {
      this.setState({
        loading: true,
      });
    }

    API.getStoreByID(this.props.match?.params?.storeID)
      .then(
        (data: any) => {
          const store = data?.data?.data?.store;

          if (!store || !DateUtils.storeCanScheduleConsultations(store)) {
            this.props.history.push("/schedule");

            return;
          }

          this.setState({
            store,
          });
        },
        (err: any) => {
          console.error(err);

          this.props.history.push("/schedule");
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  loadAppointmentType() {
    const type: any = this.props.match?.params?.appointmentType;

    const appointmentType = Constants.APPOINTMENT_TYPES[type];

    if (!appointmentType) {
      this.props.history.push("/schedule");

      return;
    }

    this.setState({
      appointmentType,
    });
  }

  componentDidMount(): void {
    this.loadAppointmentType();

    StorageUtils.get("scheduler_store").then((store: any) => {
      if (
        this.state.store?._id != this?.props?.match?.params?.storeID ||
        !store
      ) {
        this.loadStore();

        return;
      }

      if (!DateUtils.storeIsOpenRetail(store)) {
        this.props.history.push("/schedule");

        return;
      }

      this.setState(
        {
          store,
        },
        () => {
          this.loadStore();
        }
      );
    });
  }

  async selectStore(store: any) {
    if (!store) {
      return;
    }

    console.log(store);

    // TODO: set store for lead
    // TODO: save lead result into localstorage / storage api

    this.setState({
      store,
    });

    console.log("HERE");

    await StorageUtils.set("scheduler_store", store);

    this.props.history?.push(`/schedule/${this.state.store?._id}/nc-consult`);
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      this.setState(
        {
          store: null,
        },
        () => {
          this.loadStore();
        }
      );
    }

    if (
      this.props.match?.params?.appointmentType !=
      prevProps?.match?.params?.appointmentType
    ) {
      this.setState(
        {
          store: null,
        },
        () => {
          this.loadAppointmentType();
        }
      );
    }
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders
          title={`Availability For ${
            this.state.store?.name
              ? this.state.store?.name?.replace("Project LeanNation", "PLN")
              : "Your Location"
          }`}
        ></SEOHeaders>
        <SchedulerNavbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          activeStep={2}
          showBackButton={true}
          onGoBack={() => {
            this.props.history.push("/schedule");
          }}
        ></SchedulerNavbar>
        <IonContent>
          <div className="pb-lg-4 pt-3 bg-white">
            {/** Desktop View */}
            <div
              className="lg:p-4 bg-white"
              style={{
                maxWidth: 1200,
                margin: "auto",
                overflow: "hidden",
              }}
            >
              <div className="grid grid-cols-1 gap-x-4 lg:grid-cols-3 lg:px-4">
                <div className="border-b h-auto lg:col-span-1 lg:border border-gray-100 lg:rounded-lg p-[0.75rem] pb-[1.5rem]  lg:p-5 mb-[1rem] lg:mb-[0] bg-white">
                  <div className="flex justify-center lg:justify-start">
                    <div
                      onClick={() => {
                        this.props.history.push("/schedule");
                      }}
                      className="cursor-pointer hover:border-orange-500 lg:w-[100%] transition-all flex gap-x-4 border-[1px] p-[0.75rem] border-gray-100 rounded-lg items-center lg:justify-start"
                    >
                      <div>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                        >
                          <rect
                            y="0.5"
                            width="20"
                            height="20"
                            rx="10"
                            fill="#FB6340"
                          />
                          <path
                            d="M13.0194 7.51936L5.61503 10.885L9.42938 11.1093L9.65376 14.9237L13.0194 7.51936Z"
                            fill="white"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="lg:grow">
                        <h5 className="text-dark m-0 font-weight-bold">
                          {this.state.store?.name
                            ?.replace("Project LeanNation", "PLN")
                            .trim()}
                        </h5>
                        <p className="m-0 text-sm" style={{ lineHeight: 1.2 }}>
                          {
                            this.state.store?.location?.address
                              ?.formattedAddress
                          }
                        </p>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="w-5 h-5"
                        >
                          <title>pencil</title>
                          <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[0.75rem] lg:mt-[1rem]">
                    <h4 className="text-gray-900 px-2 px-lg-0 text-center text-lg-left font-weight-bold text-lg md:text-xl mt-0">
                      Select A Time For Your {this.state?.appointmentType?.NAME}
                    </h4>
                    <p
                      className="m-0 text-gray-900 px-2 px-lg-0 text-center text-lg-left max-w-[500px] mx-auto"
                      style={{ lineHeight: 1.5 }}
                    >
                      {this.state?.appointmentType?.DESCRIPTION}
                    </p>
                    <div className="flex justify-center lg:justify-start">
                      <p
                        className="mt-3 mb-0 rounded-lg p-1 bg-gray-25 border-gray-100 border inline-block text-gray-900 text-center text-lg-left font-weight-500"
                        style={{ fontSize: 14 }}
                      >
                        <span
                          style={{ position: "relative", top: 3 }}
                          className="pr-1"
                        >
                          <IonIcon color="dark" icon={timeOutline}></IonIcon>
                        </span>
                        {this.state?.appointmentType?.DURATION_READABLE}{" "}
                        Appointment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="px-[1rem] pb-5 lg:mx-[0.5rem] lg:p-5 lg:border lg:border-gray-100 lg:rounded-lg lg:col-span-2">
                  <SchedulingCalendarV2
                    store={this.state.store}
                    appointmentType={this.state.appointmentType}
                    value={this.state.consultationDate}
                    onDateSelected={(date: any) => {
                      this.setState({
                        consultationDate: date,
                      });

                      if (this.state.consultationDate !== date) {
                        this.setState({
                          consultationDateTime: null,
                        });
                      }

                      this.props.history.push(
                        `/schedule/${this.state.store?._id}/${
                          this.props.match?.params?.appointmentType
                        }/${date.toISOString()}`
                      );
                    }}
                  ></SchedulingCalendarV2>
                </div>
              </div>
            </div>
          </div>
          <Footer bgWhite={true}></Footer>
        </IonContent>
      </IonPage>
    );
  }
}

export default Availability;
