import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./Details.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import {
  navigateCircleOutline,
  thumbsUpSharp,
  timeOutline,
} from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import SchedulingCalendar from "../../../../components/Scheduler/SchedulingCalendar/SchedulingCalendar";
import SchedulingHours from "../../../../components/Scheduler/SchedulingHours/SchedulingHours";
import moment from "moment";
import SchedulingForm from "../../../../components/Scheduler/SchedulingForm/SchedulingForm";
import StringUtils from "../../../../lib/StringUtils";
import SEOHeaders from "../../../../components/SEOHeaders";

class Details extends React.Component<{ history: any; match: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    mode: "selectstore",
    fields: [],
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  loadStore() {
    if (!this.state.store) {
      this.setState({
        loading: true,
      });
    }

    API.getStoreByID(this.props.match?.params?.storeID)
      .then(
        (data: any) => {
          const store = data?.data?.data?.store;

          if (!store || !DateUtils.storeCanScheduleConsultations(store)) {
            this.props.history.push("/schedule");

            return;
          }

          this.setState({
            store,
          });
        },
        (err: any) => {
          console.error(err);

          this.props.history.push("/schedule");
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  async loadAppointmentType() {
    const type: any = this.props.match?.params?.appointmentType;

    const appointmentType = Constants.APPOINTMENT_TYPES[type];

    if (!appointmentType) {
      this.props.history.push("/schedule");

      return;
    }

    const fields = appointmentType.FIELDS;

    const leadID = await StorageUtils.get("scheduler_leadID", true);
    const customerID = await StorageUtils.get("scheduler_customerID", true);
    const email = await StorageUtils.get("scheduler_email");
    const phone = await StorageUtils.get("scheduler_phone", true);
    const name = await StorageUtils.get("scheduler_name");

    console.log("CUSTOMER ID", customerID);

    const requiredFields = _.filter(fields, (field: any) => {
      if (leadID || customerID) {
        return (
          field != "name" &&
          field != "email" &&
          field != "phone" &&
          field != "goal" &&
          field != "notes"
        );
      }

      return field != "goal" && field != "notes";
    });

    let formValues: any = {};

    if (email || phone || name) {
      formValues = {
        email,
        phone,
        name,
        goal: "",
        notes: "",
      };
    } else {
      formValues = {
        email: "",
        phone: "",
        name: "",
        goal: "",
        notes: "",
      };
    }

    console.log(formValues);

    this.setState({
      appointmentType,
      fields,
      requiredFields,
      initialValues: formValues,
      formValues: formValues,
    });
  }

  loadDateTime() {
    const dateTime: any = this.props.match?.params?.dateTime;
    const now = moment().add(10, "minutes");

    const date = moment(dateTime);

    if (!date || date.isBefore(now)) {
      console.log("HERE", date);

      /*this.props.history.push(
        `/schedule/${this.props.match?.params?.storeID}/${this.props.match?.params?.appointmentType}`
      );*/

      return;
    }

    this.setState({
      dateMoment: date,
    });
  }

  componentDidMount(): void {
    this.loadAppointmentType();
    this.loadDateTime();

    StorageUtils.get("scheduler_store").then((store: any) => {
      if (
        this.state.store?._id != this?.props?.match?.params?.storeID ||
        !store
      ) {
        this.loadStore();

        return;
      }

      if (!DateUtils.storeIsOpenRetail(store)) {
        this.props.history.push("/schedule");

        return;
      }

      this.setState(
        {
          store,
        },
        () => {
          this.loadStore();
        }
      );
    });
  }

  formatDateMoment() {
    return this.state.dateMoment?.format("dddd, MMMM Do, YYYY [at] h:mm a");
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.props.match?.params?.storeID != prevProps?.match?.params?.storeID
    ) {
      this.setState(
        {
          store: null,
        },
        () => {
          this.loadStore();
        }
      );
    }

    if (
      this.props.match?.params?.appointmentType !=
      prevProps?.match?.params?.appointmentType
    ) {
      this.loadAppointmentType();
    }

    if (
      this.props.match?.params?.dateTime !=
        prevProps?.match?.params?.dateTime &&
      this.props.match?.params?.dateTime
    ) {
      this.loadDateTime();
    }
  }

  hasAllFields() {
    let hasAll = true;

    if (!this.state.requiredFields?.length) {
      return true;
    }

    this.state.requiredFields?.forEach((field: any) => {
      if (!this.state.formValues[field]) {
        hasAll = false;
      }
    });

    return hasAll;
  }

  setError(message = "") {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 5000);
    }
  }

  async schedule() {
    const win: any = window;

    const self: any = this;

    win.grecaptcha.ready(function () {
      win.grecaptcha
        .execute(Constants.GOOGLE_RECAPTCHA_KEY, { action: "submit" })
        .then((token: any) => {
          self.setState({
            submitting: true,
          });

          self.setError("");

          let phone = self.state.formValues?.phone
            ? StringUtils.formatPhoneForServer(self.state.formValues?.phone)
            : "";

          let email = self.state.formValues?.email
            ? self.state.formValues?.email
            : "";
          let name = self.state.formValues?.name
            ? self.state.formValues?.name
            : "";

          let payload: any = {
            leadID: self.state.leadID ? self.state.leadID : null,
            customerID: self.state.customerID ? self.state.customerID : null,
            appointmentType: self.state.appointmentType?.APPOINTMENT_TYPE,
            dateTime: self.state.dateMoment.toDate(),
            name: name,
            email: email,
            phone: phone,
            notes: self.state.formValues?.notes
              ? self.state.formValues?.notes
              : "",
            goal: self.state.formValues?.goal
              ? self.state.formValues?.goal
              : "",
            forceAppointmentType: false,
            captchaToken: token,
          };

          API.scheduleAppointment(self.state.store?._id, payload)
            .then(
              async (data: any) => {
                console.log(data?.data?.data);

                self.props.history.push(
                  `/schedule/appointments/${data?.data?.data?.appointment?._id}`
                );
              },
              (err) => {
                self.setError(
                  `Unable to schedule: ${
                    err?.response?.data?.message
                      ? err?.response?.data?.message
                      : "Unknown error occurred."
                  } Please try again`
                );
              }
            )
            .finally(() => {
              self.setState({
                submitting: false,
              });
            });
        });
    });
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders title={`Confirm Your Appointment`}></SEOHeaders>
        <SchedulerNavbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          activeStep={3}
          showBackButton={true}
          onGoBack={() => {
            this.props.history.push(
              `/schedule/${this.props.match?.params?.storeID}/${this.props.match?.params?.appointmentType}`
            );
          }}
        ></SchedulerNavbar>
        <IonContent className="bg-white">
          <div
            className="pb-lg-4 px-3"
            style={{ minHeight: "calc(100vh - 160px)" }}
          >
            {/** Desktop View */}
            <div
              className="px-md-3 py-[2rem]"
              style={{
                maxWidth: 1200,
                margin: "auto",
                overflow: "hidden",
              }}
            >
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol
                    size-xs={12}
                    size-md={12}
                    size-lg={5}
                    className="py-0"
                  >
                    <div
                      className="border p-3 mb-4 bg-white"
                      style={{ borderRadius: 6 }}
                    >
                      <h1 className="text-gray-900 font-bold leading-12 mt-0 mb-2 text-center text-lg-left text-2xl">
                        Schedule Your {this.state?.appointmentType?.NAME}
                      </h1>
                      <p
                        className="m-0 text-gray-900 text-base leading-6  text-center text-lg-left"
                        style={{ lineHeight: 1.5 }}
                      >
                        {this.state?.appointmentType?.DESCRIPTION}
                      </p>

                      <hr className="my-3 border-gray-100"></hr>
                      <h3 className="text-gray-900 font-bold text-center text-lg-left mb-2">
                        Date & Time:
                      </h3>
                      <p className="text-dark text-base lg:text-lg text-center text-lg-left m-0">
                        {this.formatDateMoment()}
                      </p>
                      <hr className="my-3 border-gray-100"></hr>
                      <h3 className="text-gray-900 font-bold text-center text-lg-left mb-2">
                        Location:
                      </h3>
                      <h5 className="text-dark text-base lg:text-lg text-center text-lg-left m-0">
                        {this.state.store?.name
                          ?.replace("Project LeanNation", "PLN")
                          .trim()}
                      </h5>
                      <p
                        className="m-0 text-sm lg:text-base text-center text-lg-left"
                        style={{ lineHeight: 1.2 }}
                      >
                        {this.state.store?.location?.address?.formattedAddress}
                      </p>
                      <p
                        className="mt-1 mb-0 text-sm lg:text-base text-center text-lg-left"
                        style={{ lineHeight: 1.2 }}
                      >
                        {this.state.store?.birdEnabled
                          ? StringUtils.formatPhoneNumber(
                              this.state.store?.birdPhone?.identifier?.replace(
                                "+1",
                                ""
                              )
                            )
                          : this.state.store?.twilioNumber
                          ? StringUtils.formatPhoneNumber(
                              this.state.store?.twilioNumber?.replace("+1", "")
                            )
                          : StringUtils.formatPhoneNumber(
                              this.state.store?.contact?.phone?.replace(
                                "+1",
                                ""
                              )
                            )}
                      </p>
                      <hr className="my-3 border-gray-100"></hr>
                      <p
                        className="mt-1 mb-0 text-sm lg:text-base text-center text-lg-left"
                        style={{ lineHeight: 1.2 }}
                      >
                        You'll receive a confirmation email once you've
                        scheduled your appointment.
                      </p>
                    </div>
                  </IonCol>
                  <IonCol
                    size-xs={12}
                    size-md={12}
                    size-lg={7}
                    className="py-0 mt-lg-0"
                  >
                    <SchedulingForm
                      bgWhite={true}
                      appointmentType={this.state.appointmentType}
                      title="Enter Your Information Below To Confirm Your Appointment"
                      fields={this.state.fields}
                      requiredFields={this.state.requiredFields}
                      onChange={(vals: any) => {
                        this.setState({
                          formValues: vals,
                        });
                      }}
                      initialValue={this.state.initialValues}
                      value={this.state.formValues}
                    ></SchedulingForm>

                    <IonButton
                      onClick={() => {
                        this.schedule();
                      }}
                      color="primary"
                      disabled={
                        !this.hasAllFields() ||
                        !this.state.dateMoment ||
                        this.state.submitting
                      }
                      className="pln-button-web mt-4"
                      expand="block"
                      mode="ios"
                    >
                      {this.state.submitting ? (
                        <IonSpinner name="dots"></IonSpinner>
                      ) : (
                        <>Schedule</>
                      )}
                    </IonButton>
                    {this.state.error ? (
                      <p className="text-danger mb-0 mt-2">
                        {this.state.error}
                      </p>
                    ) : null}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
          <Footer></Footer>
        </IonContent>
      </IonPage>
    );
  }
}

export default Details;
