import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonToast,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./ForgotPassword.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import {
  chatboxEllipses,
  chevronForward,
  eye,
  eyeOff,
  mail,
  mailUnread,
  navigateCircleOutline,
} from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import carousel1 from "../../../../assets/img/backgrounds/login_carousel_1-min.jpg";
import carousel2 from "../../../../assets/img/backgrounds/login_carousel_2-min.jpg";
import carousel3 from "../../../../assets/img/backgrounds/login_carousel_3-min.jpg";
import carousel4 from "../../../../assets/img/backgrounds/login_carousel_4-min.jpg";
import { Capacitor } from "@capacitor/core";
import StringUtils from "../../../../lib/StringUtils";
import { Link } from "react-router-dom";
import SEOHeaders from "../../../../components/SEOHeaders";

class ForgotPassword extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    slide: 1,
    mode: "select",
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  handleTransition() {
    const interval = setInterval(() => {
      let slide = this.state.slide;

      if (slide == 4) {
        slide = 1;
      } else {
        slide = slide + 1;
      }

      this.setState({
        slide,
      });
    }, 10000);

    this.setState({
      slideInterval: interval,
    });
  }

  componentDidMount(): void {
    this.handleTransition();
  }

  componentWillUnmount(): void {
    if (this.state.slideInterval) {
      clearInterval(this.state.slideInterval);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {}

  setError(message: string) {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setError("");
      }, 7000);
    }
  }

  sendRecovery(method = "", value = "") {
    this.setState({
      submitting: true,
    });

    API.sendForgotPasswordVerification(method, value)
      .then(
        (data: any) => {
          const resp = data?.data?.data;

          //          console.log(resp);

          if (method == "email") {
            this.setState({
              mode: "emailconfirm",
            });
          }

          if (method == "phone") {
            this.setState({
              mode: "phoneverify",
              verificationCode: resp?.verificationToken,
            });
          }
        },
        (e) => {
          console.error(e);

          this.setError(
            `We're having trouble locating your account Please confirm the ${method} you provided is correct and try again.`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  verifyCode() {
    this.setState({
      submitting: true,
    });

    //  console.log(this.state.code, this.state.verificationCode);

    API.verifyForgotPassword(this.state.verificationCode, this.state.code)
      .then(
        (data: any) => {
          const resp = data?.data?.data;

          this.setState({
            mode: "select",
            phone: "",
            phoneReadable: "",
            email: "",
            verificationCode: "",
            code: "",
          });

          this.props.history.push(
            `/login/reset?token=${resp?.verificationToken}`
          );
        },
        (e: any) => {
          console.error(e);

          this.setError(
            "The verification code you entered is incorrect. Please try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders title="Request Password Reset"></SEOHeaders>
        {Capacitor.isNativePlatform() && (
          <>
            <IonHeader>
              <IonToolbar className="pln-native-toolbar">
                <IonTitle>Recovery</IonTitle>
              </IonToolbar>
            </IonHeader>
          </>
        )}
        <IonContent>
          <div className="pb-lg-4" style={{}}>
            {/** Desktop View */}
            <div
              className="pln-store-selector-container"
              style={{
                maxWidth: 1200,
                margin: "auto",
                maxHeight: 800,
                overflow: "hidden",
              }}
            >
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol
                    size-xs={12}
                    size-md={6}
                    size-lg={5}
                    className="pt-0 px-0 pb-0 left-col"
                    style={{
                      borderRight: "1px solid var(--lighter)",
                      borderLeft: "1px solid var(--lighter)",
                      background: "var(--white)",
                    }}
                  >
                    <div
                      className="px-4 py-4 text-center d-flex ion-justify-content-center ion-align-items-center"
                      style={{
                        minHeight: this.state.showPassword ? 660 : 600,
                        height: "calc(100vh)",
                        maxHeight: 800,
                      }}
                    >
                      <div>
                        <img
                          src={plnSymbol}
                          alt="Project LeanNation Logo"
                          style={{ height: 60, width: 60 }}
                        ></img>

                        <div>
                          {this.state.mode == "select" ? (
                            <>
                              <h1 className="mt-3 mb-0 font-weight-bold">
                                Account Recovery
                              </h1>
                              <p
                                className="small text-medium mt-2"
                                style={{ lineHeight: 1.3 }}
                              >
                                Let's get you back into your account!
                              </p>
                              <div
                                className="mt-4 mb-4 pb-2 text-dark font-weight-500 text-center"
                                style={{ fontSize: 18 }}
                              >
                                How would you like to recover your account?
                              </div>

                              <div className="mt-3">
                                {this.state.error && (
                                  <div className="mb-3 text-danger">
                                    {this.state.error}
                                  </div>
                                )}
                                <IonCard
                                  button={true}
                                  mode="ios"
                                  className="shadow-sm border mx-0 mt-0 mb-3"
                                  onClick={() => {
                                    this.setState({
                                      mode: "sms",
                                    });
                                  }}
                                >
                                  <IonCardContent className="p-3">
                                    <IonRow className="ion-align-items-center">
                                      <IonCol size-xs="auto">
                                        <div
                                          style={{
                                            color: "var(--primary)",
                                            fontSize: 32,
                                            lineHeight: 1,
                                          }}
                                        >
                                          <IonIcon
                                            icon={chatboxEllipses}
                                          ></IonIcon>
                                        </div>
                                      </IonCol>
                                      <IonCol
                                        size-xs=""
                                        className="text-left pl-3"
                                      >
                                        <h2 className="mt-0 mb-1 text-dark font-weight-bold">
                                          Text Message
                                        </h2>
                                        <p
                                          className="m-0 text-muted"
                                          style={{ lineHeight: 1.2 }}
                                        >
                                          Send me a text message containing a
                                          verification code.
                                        </p>
                                      </IonCol>
                                      <IonCol size-xs="auto" className="pl-2">
                                        <div
                                          style={{
                                            color: "var(--dark)",
                                            fontSize: 25,
                                            lineHeight: 1,
                                          }}
                                        >
                                          <IonIcon
                                            icon={chevronForward}
                                          ></IonIcon>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonCardContent>
                                </IonCard>
                                <IonCard
                                  button={true}
                                  mode="ios"
                                  className="shadow-sm border m-0"
                                  onClick={() => {
                                    this.setState({
                                      mode: "email",
                                    });
                                  }}
                                >
                                  <IonCardContent className="p-3">
                                    <IonRow className="ion-align-items-center">
                                      <IonCol size-xs="auto">
                                        <div
                                          style={{
                                            color: "var(--primary)",
                                            fontSize: 32,
                                            lineHeight: 1,
                                          }}
                                        >
                                          <IonIcon icon={mail}></IonIcon>
                                        </div>
                                      </IonCol>
                                      <IonCol
                                        size-xs=""
                                        className="text-left pl-3"
                                      >
                                        <h2 className="mt-0 mb-1 text-dark font-weight-bold">
                                          Email
                                        </h2>
                                        <p
                                          className="m-0 text-muted"
                                          style={{ lineHeight: 1.2 }}
                                        >
                                          Send me an email containing a link to
                                          verify my identity.
                                        </p>
                                      </IonCol>
                                      <IonCol size-xs="auto" className="pl-2">
                                        <div
                                          style={{
                                            color: "var(--dark)",
                                            fontSize: 25,
                                            lineHeight: 1,
                                          }}
                                        >
                                          <IonIcon
                                            icon={chevronForward}
                                          ></IonIcon>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                  </IonCardContent>
                                </IonCard>
                                <p
                                  className="small text-medium mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  Landed here by mistake?{" "}
                                  <Link to="/login" className="text-medium">
                                    Return to Login
                                  </Link>
                                </p>
                              </div>
                            </>
                          ) : null}
                          {this.state.mode == "email" ? (
                            <>
                              <>
                                <h1 className="mt-3 mb-0 font-weight-bold">
                                  Enter Your Email
                                </h1>
                                <p
                                  className="small text-medium mt-2"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  We'll send you an email containing a recover
                                  your account.
                                  <br></br>
                                  <a
                                    href="#"
                                    rel="noreferrer"
                                    onClick={(e: any) => {
                                      e.preventDefault();

                                      this.setState({
                                        mode: "select",
                                      });
                                    }}
                                    className="text-medium"
                                  >
                                    Choose Another Method
                                  </a>
                                </p>

                                <form
                                  onSubmit={(e: any) => {
                                    e.preventDefault();

                                    this.sendRecovery(
                                      "email",
                                      this.state.email
                                    );
                                  }}
                                >
                                  <div className="mt-4">
                                    {this.state.error && (
                                      <div className="mb-3 text-danger">
                                        {this.state.error}
                                      </div>
                                    )}
                                    <IonItem
                                      color="primary"
                                      className={`pln-input-web small  mb-3 mb-md-4`}
                                      mode="md"
                                      fill="outline"
                                    >
                                      <IonLabel position="floating">
                                        Email
                                      </IonLabel>
                                      <IonInput
                                        autocapitalize="none"
                                        inputMode="email"
                                        color="primary"
                                        type="email"
                                        name="email"
                                        autocomplete="email"
                                        value={this.state.email}
                                        placeholder="Email"
                                        required={true}
                                        clearInput={true}
                                        onIonChange={(event: any) => {
                                          this.setState({
                                            email: event?.detail?.value,
                                          });
                                        }}
                                      ></IonInput>
                                    </IonItem>
                                    <IonButton
                                      type="submit"
                                      className="pln-button-web"
                                      mode="ios"
                                      expand={"block"}
                                      color="primary"
                                      disabled={
                                        !this.state.email ||
                                        !StringUtils.isValidEmail(
                                          this.state.email
                                        ) ||
                                        this.state.submitting
                                      }
                                      onClick={() => {
                                        this.sendRecovery(
                                          "email",
                                          this.state.email
                                        );
                                      }}
                                    >
                                      {this.state.submitting ? (
                                        <>
                                          <IonSpinner name="dots"></IonSpinner>
                                        </>
                                      ) : (
                                        <>Email My Link</>
                                      )}
                                    </IonButton>
                                    <button
                                      type="submit"
                                      style={{
                                        position: "absolute",
                                        visibility: "hidden",
                                      }}
                                    >
                                      submit
                                    </button>
                                  </div>
                                </form>
                                <p
                                  className="small text-medium mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  Landed here by mistake?{" "}
                                  <Link to="/login" className="text-medium">
                                    Return to Login
                                  </Link>
                                </p>
                              </>
                            </>
                          ) : null}
                          {this.state.mode == "emailconfirm" ? (
                            <>
                              <>
                                <h1 className="mt-3 mb-0 font-weight-bold">
                                  Check Your Email For A Recovery Link
                                </h1>
                                <p
                                  className="small text-medium mt-2"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  An email containing a link to recover your
                                  identity has been sent to the email
                                  address&nbsp;
                                  <span className="text-dark font-weight-500">
                                    {this.state.email}
                                  </span>
                                  . Click the link inside the email to update
                                  your password.
                                </p>
                                <p className="small text-medium mt-2">
                                  Didn't receive it?{" "}
                                  <a
                                    href="#"
                                    rel="noreferrer"
                                    onClick={(e: any) => {
                                      e.preventDefault();

                                      this.sendRecovery(
                                        "email",
                                        this.state.email
                                      );
                                    }}
                                    className="text-medium"
                                  >
                                    {this.state.submitting
                                      ? "Resending..."
                                      : "Resend Email"}
                                  </a>
                                </p>
                              </>
                            </>
                          ) : null}
                          {this.state.mode == "sms" ? (
                            <>
                              <>
                                <h1 className="mt-3 mb-0 font-weight-bold">
                                  Enter Your Phone Number
                                </h1>
                                <p
                                  className="small text-medium mt-2"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  We'll send you a text message containing a
                                  verification code to recover your account.
                                  <br></br>
                                  <a
                                    href="#"
                                    rel="noreferrer"
                                    onClick={(e: any) => {
                                      e.preventDefault();

                                      this.setState({
                                        mode: "select",
                                      });
                                    }}
                                    className="text-medium"
                                  >
                                    Choose Another Method
                                  </a>
                                </p>
                                <form
                                  onSubmit={(e: any) => {
                                    e.preventDefault();

                                    this.sendRecovery(
                                      "phone",
                                      this.state.phone
                                    );
                                  }}
                                >
                                  <div className="mt-4">
                                    {this.state.error && (
                                      <div className="mb-3 text-danger">
                                        {this.state.error}
                                      </div>
                                    )}
                                    <IonItem
                                      color="primary"
                                      className={`pln-input-web small mb-3 mb-sm-4`}
                                      mode="md"
                                      fill="outline"
                                    >
                                      <IonLabel position="floating">
                                        Phone
                                      </IonLabel>
                                      <IonInput
                                        autocapitalize="none"
                                        inputMode="tel"
                                        color="primary"
                                        name="phone"
                                        autocomplete="tel"
                                        //pattern="email"
                                        placeholder="Phone"
                                        required={true}
                                        clearInput={true}
                                        value={this.state.phoneReadable}
                                        onIonChange={(event: any) => {
                                          const res: any =
                                            StringUtils.formatPhoneNumberWhileTyping(
                                              event?.detail?.value?.toString()
                                            );

                                          this.setState({
                                            phone: res?.value
                                              ?.split(" ")
                                              .join(""),
                                            phoneReadable: res?.formatted,
                                          });
                                        }}
                                        onIonBlur={() => {}}
                                      ></IonInput>
                                    </IonItem>
                                    <IonButton
                                      type="submit"
                                      className="pln-button-web"
                                      mode="ios"
                                      expand={"block"}
                                      color="primary"
                                      disabled={
                                        !this.state.phone ||
                                        !StringUtils.isValidPhone(
                                          this.state.phone
                                        ) ||
                                        this.state.submitting
                                      }
                                      onClick={() => {
                                        this.sendRecovery(
                                          "phone",
                                          this.state.phone
                                        );
                                      }}
                                    >
                                      {this.state.submitting ? (
                                        <>
                                          <IonSpinner name="dots"></IonSpinner>
                                        </>
                                      ) : (
                                        <>Send Code</>
                                      )}
                                    </IonButton>
                                    <button
                                      type="submit"
                                      style={{
                                        position: "absolute",
                                        visibility: "hidden",
                                      }}
                                    >
                                      submit
                                    </button>
                                  </div>
                                </form>
                                <p
                                  className="small text-medium mt-4"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  Landed here by mistake?{" "}
                                  <Link to="/login" className="text-medium">
                                    Return to Login
                                  </Link>
                                </p>
                              </>
                            </>
                          ) : null}
                          {this.state.mode == "phoneverify" ? (
                            <>
                              <>
                                <h1 className="mt-3 mb-0 font-weight-bold">
                                  Enter Your Verification Code
                                </h1>
                                <p
                                  className="small text-medium mt-2"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  A text message containing a verification code
                                  has been sent to the phone number:{" "}
                                  <span className="font-weight-500 text-dark">
                                    {this.state.phoneReadable}
                                  </span>
                                  . Enter the code below to continue.
                                  <br></br>
                                  <p className="small text-medium mt-2">
                                    Didn't receive it?{" "}
                                    <a
                                      href="#"
                                      rel="noreferrer"
                                      onClick={(e: any) => {
                                        e.preventDefault();

                                        this.sendRecovery(
                                          "phone",
                                          this.state.phone
                                        );
                                      }}
                                      className="text-medium"
                                    >
                                      {this.state.submitting
                                        ? "Resending..."
                                        : "Resend Code"}
                                    </a>
                                  </p>
                                </p>

                                <form
                                  onSubmit={(e: any) => {
                                    e.preventDefault();

                                    this.verifyCode();
                                  }}
                                >
                                  <div className="mt-4">
                                    {this.state.error && (
                                      <div className="mb-3 text-danger">
                                        {this.state.error}
                                      </div>
                                    )}
                                    <IonItem
                                      color="primary"
                                      className={`pln-input-web small  mb-3 mb-md-4`}
                                      mode="md"
                                      fill="outline"
                                    >
                                      <IonLabel position="floating">
                                        Verification Code
                                      </IonLabel>
                                      <IonInput
                                        autocapitalize="none"
                                        inputMode="text"
                                        color="primary"
                                        type="text"
                                        name="email"
                                        autocomplete="one-time-code"
                                        value={this.state.code}
                                        placeholder="Verification Code"
                                        required={true}
                                        clearInput={true}
                                        onIonChange={(event: any) => {
                                          this.setState({
                                            code: event?.detail?.value,
                                          });
                                        }}
                                      ></IonInput>
                                    </IonItem>
                                    <IonButton
                                      type="submit"
                                      className="pln-button-web"
                                      mode="ios"
                                      expand={"block"}
                                      color="primary"
                                      disabled={
                                        !this.state.code ||
                                        this.state.submitting
                                      }
                                      onClick={() => {
                                        this.verifyCode();
                                      }}
                                    >
                                      {this.state.submitting ? (
                                        <>
                                          <IonSpinner name="dots"></IonSpinner>
                                        </>
                                      ) : (
                                        <>Verify</>
                                      )}
                                    </IonButton>
                                    <button
                                      type="submit"
                                      style={{
                                        position: "absolute",
                                        visibility: "hidden",
                                      }}
                                    >
                                      submit
                                    </button>
                                  </div>
                                </form>
                              </>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </IonCol>
                  <IonCol
                    className="d-none d-md-flex p-0 right-col"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel1}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 3 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel2}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 2 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel3}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 4 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: `url(${carousel4}) center center / cover`,
                      }}
                      className={`login-slide-transition ${
                        this.state.slide == 1 && "show"
                      }`}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: "1rem",
                        bottom: "1rem",
                        width: "calc(100%)",
                        maxWidth: 500,
                        paddingRight: "1rem",
                      }}
                    >
                      <div
                        className={`${
                          this.state.slide == 1 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Personalized nutrition plans designed for your goals.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN Ballantyne
                        </p>
                      </div>
                      <div
                        className={`${
                          this.state.slide == 2 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Transforming health with delicious, ready-to-heat
                          meals.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN Plaza
                        </p>
                      </div>
                      <div
                        className={`${
                          this.state.slide == 3 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Coaching &amp; check-ins keep you on track for
                          success.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN Concord
                        </p>
                      </div>
                      <div
                        className={`${
                          this.state.slide == 4 ? "d-block" : "d-none"
                        }`}
                      >
                        <h1 className="text-white m-0 font-weight-bold">
                          Flexible plans to fit your lifestyle.
                        </h1>
                        <p className="text-white font-weight-500 mb-0 mt-2 ">
                          PLN South End
                        </p>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
          <Footer></Footer>
        </IonContent>
      </IonPage>
    );
  }
}

export default ForgotPassword;
