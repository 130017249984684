import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./Confirmation.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import { navigateCircleOutline, timeOutline } from "ionicons/icons";

import { GoogleMap, Marker } from "@capacitor/google-maps";
import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import SchedulingCalendar from "../../../../components/Scheduler/SchedulingCalendar/SchedulingCalendar";
import SchedulingHours from "../../../../components/Scheduler/SchedulingHours/SchedulingHours";
import moment from "moment";
import SchedulingForm from "../../../../components/Scheduler/SchedulingForm/SchedulingForm";
import StringUtils from "../../../../lib/StringUtils";
import AppointmentInformation from "../../../../components/Scheduler/AppointmentInformation/AppointmentInformation";
import InlineReschedulingModal from "../../../../components/Scheduler/SchedulingCalendar/InlineReschedulingModal";
import SEOHeaders from "../../../../components/SEOHeaders";

class Confirmation extends React.Component<{ history: any; match: any }> {
  state: any = {
    appointment: null,
    loading: false,
  };

  pageRef: any = React.createRef();

  async loadAppointment() {
    const id: any = this.props.match?.params?.appointmentType;

    console.log(id);

    this.setState({
      loading: true,
    });

    API.getAppointmentByID(id).then(
      (data: any) => {
        this.setState({
          appointment: data?.data?.data?.appointment,
        });
      },
      (e) => {
        console.error(e);

        this.props.history.push("/schedule");
      }
    );
  }

  componentDidMount(): void {
    this.loadAppointment();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.props.match?.params?.appointmentType !=
      prevProps?.match?.params?.appointmentType
    ) {
      this.loadAppointment();
    }
  }

  setError(message = "") {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 5000);
    }
  }

  async schedule() {
    this.setState({
      submitting: true,
    });

    this.setError("");

    let phone = this.state.formValues?.phone
      ? StringUtils.formatPhoneForServer(this.state.formValues?.phone)
      : "";

    let email = this.state.formValues?.email
      ? this.state.formValues?.email
      : "";
    let name = this.state.formValues?.name ? this.state.formValues?.name : "";

    let payload: any = {
      leadID: this.state.leadID ? this.state.leadID : null,
      customerID: this.state.customerID ? this.state.customerID : null,
      appointmentType: this.state.appointmentType?.APPOINTMENT_TYPE,
      dateTime: this.state.dateMoment.toDate(),
      name: name,
      email: email,
      phone: phone,
      notes: this.state.formValues?.notes ? this.state.formValues?.notes : "",
      goal: this.state.formValues?.goal ? this.state.formValues?.goal : "",
      forceAppointmentType: false,
      captchaToken:
        "Uot9isGEnMoyGRFRzyaorxGUR3reqddSUVRv2Acn3tsGuHmITP8Cka72rTipj60ogaW",
    };

    API.scheduleAppointment(this.state.store?._id, payload)
      .then(
        async (data: any) => {
          console.log(data?.data?.data);

          //setAppointment(data?.data?.data?.appointment);

          /*await StorageUtils.set(
            "checkout_appointment",
            data?.data?.data?.appointment
          );*/
        },
        (err) => {
          this.setError(
            `Unable to schedule: ${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : "Unknown error occurred."
            } Please try again`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    return (
      <IonPage ref={this.pageRef}>
        <SEOHeaders title={`Appointment Confirmation`}></SEOHeaders>
        <SchedulerNavbar
          showSupportOptions={false}
          onSupportChat={() => {}}
          onSupportCall={() => {}}
          activeStep={4}
          showBackButton={false}
          onGoBack={() => {}}
          allowNavigation={false}
        ></SchedulerNavbar>
        <IonContent style={{ background: "var(--white)" }}>
          <div className="pb-lg-4" style={{ minHeight: "calc(100vh - 160px)" }}>
            {/** Desktop View */}
            <div
              className="px-3 py-3 py-lg-5"
              style={{
                maxWidth: 1200,
                margin: "auto",

                overflow: "hidden",
              }}
            >
              <IonGrid className="p-0">
                <IonRow>
                  <IonCol
                    size-xs={12}
                    size-md={12}
                    size-lg={12}
                    className="py-0"
                  >
                    <AppointmentInformation
                      appointment={this.state.appointment}
                      onChange={(appointment: any) => {
                        this.setState({
                          appointment,
                        });
                      }}
                      onOpenReschedule={() => {
                        PubSub.publish(Event.MODAL.RESCHEDULING_MODAL, "open");
                      }}
                      forceStack={false}
                    ></AppointmentInformation>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
          <Footer></Footer>
          <InlineReschedulingModal
            presentingElement={this.pageRef?.current}
            existingAppointment={this.state.appointment}
            onSave={(appointment: any) => {
              this.setState({
                appointment,
              });
            }}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default Confirmation;
